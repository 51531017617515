// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerBIReportResponse
 */
export interface PowerBIReportResponse {
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportResponse
     */
    embedUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportResponse
     */
    webUrl?: string;
}

export function PowerBIReportResponseFromJSON(json: any): PowerBIReportResponse {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'embedUrl': !exists(json, 'embedUrl') ? undefined : json['embedUrl'],
        'webUrl': !exists(json, 'webUrl') ? undefined : json['webUrl'],
    };
}

export function PowerBIReportResponseToJSON(value?: PowerBIReportResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'embedUrl': value.embedUrl,
        'webUrl': value.webUrl,
    };
}


