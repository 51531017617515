// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateRequestModel
 */
export interface UserUpdateRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequestModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequestModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequestModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequestModel
     */
    mobilePhone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequestModel
     */
    dealershipIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequestModel
     */
    dealershipGroupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequestModel
     */
    carrierIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequestModel
     */
    fiAgencyIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequestModel
     */
    partnerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequestModel
     */
    folderIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequestModel
     */
    roles: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequestModel
     */
    modifiedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequestModel
     */
    deactivationReasonType?: UserUpdateRequestModelDeactivationReasonTypeEnum;
}

export function UserUpdateRequestModelFromJSON(json: any): UserUpdateRequestModel {
    return {
        'username': json['username'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'dealershipIds': !exists(json, 'dealershipIds') ? undefined : json['dealershipIds'],
        'dealershipGroupIds': !exists(json, 'dealershipGroupIds') ? undefined : json['dealershipGroupIds'],
        'carrierIds': !exists(json, 'carrierIds') ? undefined : json['carrierIds'],
        'fiAgencyIds': !exists(json, 'fiAgencyIds') ? undefined : json['fiAgencyIds'],
        'partnerIds': !exists(json, 'partnerIds') ? undefined : json['partnerIds'],
        'folderIds': !exists(json, 'folderIds') ? undefined : json['folderIds'],
        'roles': json['roles'],
        'modifiedByUserId': json['modifiedByUserId'],
        'deactivationReasonType': !exists(json, 'deactivationReasonType') ? undefined : json['deactivationReasonType'],
    };
}

export function UserUpdateRequestModelToJSON(value?: UserUpdateRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'mobilePhone': value.mobilePhone,
        'dealershipIds': value.dealershipIds,
        'dealershipGroupIds': value.dealershipGroupIds,
        'carrierIds': value.carrierIds,
        'fiAgencyIds': value.fiAgencyIds,
        'partnerIds': value.partnerIds,
        'folderIds': value.folderIds,
        'roles': value.roles,
        'modifiedByUserId': value.modifiedByUserId,
        'deactivationReasonType': value.deactivationReasonType,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserUpdateRequestModelDeactivationReasonTypeEnum {
    Unknown = 'Unknown',
    Other = 'Other',
    SystemMisuse = 'SystemMisuse',
    UserLeftOrganization = 'UserLeftOrganization',
    OrganizationDeactivated = 'OrganizationDeactivated',
    Inactivity = 'Inactivity',
    OnboardingIssues = 'OnboardingIssues',
    IpRestricted = 'IpRestricted',
    DeviceRestricted = 'DeviceRestricted'
}


