// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreateRequestModel
 */
export interface UserCreateRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequestModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequestModel
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequestModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequestModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequestModel
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequestModel
     */
    dealershipId?: string;
}

export function UserCreateRequestModelFromJSON(json: any): UserCreateRequestModel {
    return {
        'username': json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'dealershipId': !exists(json, 'dealershipId') ? undefined : json['dealershipId'],
    };
}

export function UserCreateRequestModelToJSON(value?: UserCreateRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'password': value.password,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'mobilePhone': value.mobilePhone,
        'dealershipId': value.dealershipId,
    };
}


