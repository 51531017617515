// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsumerWorkflowStatusModel
 */
export interface ConsumerWorkflowStatusModel {
    /**
     * 
     * @type {string}
     * @memberof ConsumerWorkflowStatusModel
     */
    statusType?: ConsumerWorkflowStatusModelStatusTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ConsumerWorkflowStatusModel
     */
    statusDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ConsumerWorkflowStatusModel
     */
    isHighest?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConsumerWorkflowStatusModel
     */
    additionalProperties?: { [key: string]: object; };
}

export function ConsumerWorkflowStatusModelFromJSON(json: any): ConsumerWorkflowStatusModel {
    return {
        'statusType': !exists(json, 'statusType') ? undefined : json['statusType'],
        'statusDate': !exists(json, 'statusDate') ? undefined : new Date(json['statusDate']),
        'isHighest': !exists(json, 'isHighest') ? undefined : json['isHighest'],
        'additionalProperties': !exists(json, 'additionalProperties') ? undefined : json['additionalProperties'],
    };
}

export function ConsumerWorkflowStatusModelToJSON(value?: ConsumerWorkflowStatusModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'statusType': value.statusType,
        'statusDate': value.statusDate === undefined ? undefined : value.statusDate.toISOString(),
        'isHighest': value.isHighest,
        'additionalProperties': value.additionalProperties,
    };
}

/**
* @export
* @enum {string}
*/
export enum ConsumerWorkflowStatusModelStatusTypeEnum {
    Unknown = 'Unknown',
    New = 'New',
    TermsAccepted = 'TermsAccepted',
    PresentedQuotes = 'PresentedQuotes',
    ConnectedWithAgent = 'ConnectedWithAgent',
    DiscussedRates = 'DiscussedRates',
    VerifiedDrivingRecord = 'VerifiedDrivingRecord',
    SoldPolicy = 'SoldPolicy'
}


