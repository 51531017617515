// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PagingModel
 */
export interface PagingModel {
    /**
     * 
     * @type {number}
     * @memberof PagingModel
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof PagingModel
     */
    take?: number;
    /**
     * 
     * @type {number}
     * @memberof PagingModel
     */
    total?: number;
}

export function PagingModelFromJSON(json: any): PagingModel {
    return {
        'skip': !exists(json, 'skip') ? undefined : json['skip'],
        'take': !exists(json, 'take') ? undefined : json['take'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function PagingModelToJSON(value?: PagingModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'skip': value.skip,
        'take': value.take,
        'total': value.total,
    };
}


