// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkRequestModel
 */
export interface LinkRequestModel {
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    gender?: LinkRequestModelGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof LinkRequestModel
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {Address}
     * @memberof LinkRequestModel
     */
    address?: Address;
    /**
     * 
     * @type {Address}
     * @memberof LinkRequestModel
     */
    garagingAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    driversLicense?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    driversLicenseState?: LinkRequestModelDriversLicenseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    vin?: string;
    /**
     * 
     * @type {number}
     * @memberof LinkRequestModel
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    make: string;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof LinkRequestModel
     */
    partnerId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkRequestModel
     */
    allowInsuranceVerification?: boolean;
}

export function LinkRequestModelFromJSON(json: any): LinkRequestModel {
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : new Date(json['dateOfBirth']),
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'garagingAddress': !exists(json, 'garagingAddress') ? undefined : AddressFromJSON(json['garagingAddress']),
        'driversLicense': !exists(json, 'driversLicense') ? undefined : json['driversLicense'],
        'driversLicenseState': !exists(json, 'driversLicenseState') ? undefined : json['driversLicenseState'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'year': json['year'],
        'make': json['make'],
        'model': json['model'],
        'partnerId': !exists(json, 'partnerId') ? undefined : json['partnerId'],
        'allowInsuranceVerification': !exists(json, 'allowInsuranceVerification') ? undefined : json['allowInsuranceVerification'],
    };
}

export function LinkRequestModelToJSON(value?: LinkRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'gender': value.gender,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : value.dateOfBirth.toISOString(),
        'mobilePhone': value.mobilePhone,
        'emailAddress': value.emailAddress,
        'address': AddressToJSON(value.address),
        'garagingAddress': AddressToJSON(value.garagingAddress),
        'driversLicense': value.driversLicense,
        'driversLicenseState': value.driversLicenseState,
        'vin': value.vin,
        'year': value.year,
        'make': value.make,
        'model': value.model,
        'partnerId': value.partnerId,
        'allowInsuranceVerification': value.allowInsuranceVerification,
    };
}

/**
* @export
* @enum {string}
*/
export enum LinkRequestModelGenderEnum {
    Unspecified = 'Unspecified',
    Male = 'Male',
    Female = 'Female'
}
/**
* @export
* @enum {string}
*/
export enum LinkRequestModelDriversLicenseStateEnum {
    Unknown = 'Unknown',
    AL = 'AL',
    AK = 'AK',
    AZ = 'AZ',
    AR = 'AR',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DE = 'DE',
    DC = 'DC',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    IA = 'IA',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    ME = 'ME',
    MD = 'MD',
    MA = 'MA',
    MI = 'MI',
    MN = 'MN',
    MS = 'MS',
    MO = 'MO',
    MT = 'MT',
    NE = 'NE',
    NV = 'NV',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NY = 'NY',
    NC = 'NC',
    ND = 'ND',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VT = 'VT',
    VA = 'VA',
    WA = 'WA',
    WV = 'WV',
    WI = 'WI',
    WY = 'WY'
}


