// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemporaryTokenAuthenticationResponseModel
 */
export interface TemporaryTokenAuthenticationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof TemporaryTokenAuthenticationResponseModel
     */
    authorization?: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryTokenAuthenticationResponseModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryTokenAuthenticationResponseModel
     */
    consumerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryTokenAuthenticationResponseModel
     */
    dealershipId?: string;
}

export function TemporaryTokenAuthenticationResponseModelFromJSON(json: any): TemporaryTokenAuthenticationResponseModel {
    return {
        'authorization': !exists(json, 'authorization') ? undefined : json['authorization'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'consumerId': !exists(json, 'consumerId') ? undefined : json['consumerId'],
        'dealershipId': !exists(json, 'dealershipId') ? undefined : json['dealershipId'],
    };
}

export function TemporaryTokenAuthenticationResponseModelToJSON(value?: TemporaryTokenAuthenticationResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'authorization': value.authorization,
        'userId': value.userId,
        'consumerId': value.consumerId,
        'dealershipId': value.dealershipId,
    };
}


