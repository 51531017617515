// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StateEnabledModel
 */
export interface StateEnabledModel {
    /**
     * 
     * @type {string}
     * @memberof StateEnabledModel
     */
    stateAbbreviation: StateEnabledModelStateAbbreviationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StateEnabledModel
     */
    enabled: boolean;
}

export function StateEnabledModelFromJSON(json: any): StateEnabledModel {
    return {
        'stateAbbreviation': json['stateAbbreviation'],
        'enabled': json['enabled'],
    };
}

export function StateEnabledModelToJSON(value?: StateEnabledModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'stateAbbreviation': value.stateAbbreviation,
        'enabled': value.enabled,
    };
}

/**
* @export
* @enum {string}
*/
export enum StateEnabledModelStateAbbreviationEnum {
    Unknown = 'Unknown',
    AL = 'AL',
    AK = 'AK',
    AZ = 'AZ',
    AR = 'AR',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DE = 'DE',
    DC = 'DC',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    IA = 'IA',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    ME = 'ME',
    MD = 'MD',
    MA = 'MA',
    MI = 'MI',
    MN = 'MN',
    MS = 'MS',
    MO = 'MO',
    MT = 'MT',
    NE = 'NE',
    NV = 'NV',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NY = 'NY',
    NC = 'NC',
    ND = 'ND',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VT = 'VT',
    VA = 'VA',
    WA = 'WA',
    WV = 'WV',
    WI = 'WI',
    WY = 'WY'
}


