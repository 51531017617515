// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemporaryTokenAuthenticationRequestModel
 */
export interface TemporaryTokenAuthenticationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof TemporaryTokenAuthenticationRequestModel
     */
    token?: string;
}

export function TemporaryTokenAuthenticationRequestModelFromJSON(json: any): TemporaryTokenAuthenticationRequestModel {
    return {
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function TemporaryTokenAuthenticationRequestModelToJSON(value?: TemporaryTokenAuthenticationRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'token': value.token,
    };
}


