// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserServiceAgreementModel,
    UserServiceAgreementModelFromJSON,
    UserServiceAgreementModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserServiceAgreementsResponseModel
 */
export interface UserServiceAgreementsResponseModel {
    /**
     * 
     * @type {Array<UserServiceAgreementModel>}
     * @memberof UserServiceAgreementsResponseModel
     */
    userServiceAgreements?: Array<UserServiceAgreementModel>;
}

export function UserServiceAgreementsResponseModelFromJSON(json: any): UserServiceAgreementsResponseModel {
    return {
        'userServiceAgreements': !exists(json, 'userServiceAgreements') ? undefined : (json['userServiceAgreements'] as Array<any>).map(UserServiceAgreementModelFromJSON),
    };
}

export function UserServiceAgreementsResponseModelToJSON(value?: UserServiceAgreementsResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'userServiceAgreements': value.userServiceAgreements === undefined ? undefined : (value.userServiceAgreements as Array<any>).map(UserServiceAgreementModelToJSON),
    };
}


