// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PagingModel,
    PagingModelFromJSON,
    PagingModelToJSON,
    UserResponseModel,
    UserResponseModelFromJSON,
    UserResponseModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface UsersResponseModel
 */
export interface UsersResponseModel {
    /**
     * 
     * @type {Array<UserResponseModel>}
     * @memberof UsersResponseModel
     */
    users?: Array<UserResponseModel>;
    /**
     * 
     * @type {PagingModel}
     * @memberof UsersResponseModel
     */
    paging?: PagingModel;
}

export function UsersResponseModelFromJSON(json: any): UsersResponseModel {
    return {
        'users': !exists(json, 'users') ? undefined : (json['users'] as Array<any>).map(UserResponseModelFromJSON),
        'paging': !exists(json, 'paging') ? undefined : PagingModelFromJSON(json['paging']),
    };
}

export function UsersResponseModelToJSON(value?: UsersResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'users': value.users === undefined ? undefined : (value.users as Array<any>).map(UserResponseModelToJSON),
        'paging': PagingModelToJSON(value.paging),
    };
}


