// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PowerBIReportCreateRequestModel,
    PowerBIReportCreateRequestModelFromJSON,
    PowerBIReportCreateRequestModelToJSON,
    PowerBIReportUpdateRequestModel,
    PowerBIReportUpdateRequestModelFromJSON,
    PowerBIReportUpdateRequestModelToJSON,
    PowerBIReportsResponse,
    PowerBIReportsResponseFromJSON,
    PowerBIReportsResponseToJSON,
    PowerBIReportsResponseModel,
    PowerBIReportsResponseModelFromJSON,
    PowerBIReportsResponseModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
} from '../models';

export interface PowerBIReportDeleteByReportIdAsyncRequest {
    reportid: string;
}

export interface PowerBIReportGetByReportIdAsyncRequest {
    reportid: string;
}

export interface PowerBIReportGetReportsByOrgIdAsyncRequest {
    organizationid: string;
}

export interface PowerBIReportPostAsyncRequest {
    powerBIReportCreateRequestModel?: PowerBIReportCreateRequestModel;
}

export interface PowerBIReportPutAsyncRequest {
    internalReportId: string;
    powerBIReportUpdateRequestModel?: PowerBIReportUpdateRequestModel;
}

/**
 * no description
 */
export class PowerBIReportApi extends runtime.BaseAPI {

    /**
     */
    async powerBIReportDeleteByReportIdAsyncRaw(requestParameters: PowerBIReportDeleteByReportIdAsyncRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportid === null || requestParameters.reportid === undefined) {
            throw new runtime.RequiredError('reportid','Required parameter requestParameters.reportid was null or undefined when calling powerBIReportDeleteByReportIdAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/powerbi-reports/{reportid}`.replace(`{${"reportid"}}`, encodeURIComponent(String(requestParameters.reportid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async powerBIReportDeleteByReportIdAsync(requestParameters: PowerBIReportDeleteByReportIdAsyncRequest): Promise<void> {
        await this.powerBIReportDeleteByReportIdAsyncRaw(requestParameters);
    }

    /**
     */
    async powerBIReportGetAllReportsAvailableRaw(): Promise<runtime.ApiResponse<PowerBIReportsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/powerbi-reports/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportsResponseFromJSON(jsonValue));
    }

    /**
     */
    async powerBIReportGetAllReportsAvailable(): Promise<PowerBIReportsResponse> {
        const response = await this.powerBIReportGetAllReportsAvailableRaw();
        return await response.value();
    }

    /**
     */
    async powerBIReportGetByReportIdAsyncRaw(requestParameters: PowerBIReportGetByReportIdAsyncRequest): Promise<runtime.ApiResponse<PowerBIReportCreateRequestModel>> {
        if (requestParameters.reportid === null || requestParameters.reportid === undefined) {
            throw new runtime.RequiredError('reportid','Required parameter requestParameters.reportid was null or undefined when calling powerBIReportGetByReportIdAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/powerbi-reports/{reportid}`.replace(`{${"reportid"}}`, encodeURIComponent(String(requestParameters.reportid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportCreateRequestModelFromJSON(jsonValue));
    }

    /**
     */
    async powerBIReportGetByReportIdAsync(requestParameters: PowerBIReportGetByReportIdAsyncRequest): Promise<PowerBIReportCreateRequestModel> {
        const response = await this.powerBIReportGetByReportIdAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async powerBIReportGetReportsAsyncRaw(): Promise<runtime.ApiResponse<PowerBIReportsResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/powerbi-reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async powerBIReportGetReportsAsync(): Promise<PowerBIReportsResponseModel> {
        const response = await this.powerBIReportGetReportsAsyncRaw();
        return await response.value();
    }

    /**
     */
    async powerBIReportGetReportsByOrgIdAsyncRaw(requestParameters: PowerBIReportGetReportsByOrgIdAsyncRequest): Promise<runtime.ApiResponse<PowerBIReportsResponseModel>> {
        if (requestParameters.organizationid === null || requestParameters.organizationid === undefined) {
            throw new runtime.RequiredError('organizationid','Required parameter requestParameters.organizationid was null or undefined when calling powerBIReportGetReportsByOrgIdAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/powerbi-reports/organization-id/{organizationid}`.replace(`{${"organizationid"}}`, encodeURIComponent(String(requestParameters.organizationid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async powerBIReportGetReportsByOrgIdAsync(requestParameters: PowerBIReportGetReportsByOrgIdAsyncRequest): Promise<PowerBIReportsResponseModel> {
        const response = await this.powerBIReportGetReportsByOrgIdAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async powerBIReportPostAsyncRaw(requestParameters: PowerBIReportPostAsyncRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/powerbi-reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PowerBIReportCreateRequestModelToJSON(requestParameters.powerBIReportCreateRequestModel),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async powerBIReportPostAsync(requestParameters: PowerBIReportPostAsyncRequest): Promise<void> {
        await this.powerBIReportPostAsyncRaw(requestParameters);
    }

    /**
     */
    async powerBIReportPutAsyncRaw(requestParameters: PowerBIReportPutAsyncRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.internalReportId === null || requestParameters.internalReportId === undefined) {
            throw new runtime.RequiredError('internalReportId','Required parameter requestParameters.internalReportId was null or undefined when calling powerBIReportPutAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/powerbi-reports/{internalReportId}`.replace(`{${"internalReportId"}}`, encodeURIComponent(String(requestParameters.internalReportId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PowerBIReportUpdateRequestModelToJSON(requestParameters.powerBIReportUpdateRequestModel),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async powerBIReportPutAsync(requestParameters: PowerBIReportPutAsyncRequest): Promise<void> {
        await this.powerBIReportPutAsyncRaw(requestParameters);
    }

}
