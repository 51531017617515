// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PowerBIEmbedTokenAuthenticationResponseModel,
    PowerBIEmbedTokenAuthenticationResponseModelFromJSON,
    PowerBIEmbedTokenAuthenticationResponseModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
} from '../models';

export interface PowerBIEmbedTokensGetByIdRequest {
    internalReportId: string;
    organizationId: string;
}

/**
 * no description
 */
export class PowerBIEmbedTokensApi extends runtime.BaseAPI {

    /**
     */
    async powerBIEmbedTokensGetByIdRaw(requestParameters: PowerBIEmbedTokensGetByIdRequest): Promise<runtime.ApiResponse<PowerBIEmbedTokenAuthenticationResponseModel>> {
        if (requestParameters.internalReportId === null || requestParameters.internalReportId === undefined) {
            throw new runtime.RequiredError('internalReportId','Required parameter requestParameters.internalReportId was null or undefined when calling powerBIEmbedTokensGetById.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling powerBIEmbedTokensGetById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/reports/organizations/{organizationId}/powerbi-embed-token-authentication/{internalReportId}`.replace(`{${"internalReportId"}}`, encodeURIComponent(String(requestParameters.internalReportId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIEmbedTokenAuthenticationResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async powerBIEmbedTokensGetById(requestParameters: PowerBIEmbedTokensGetByIdRequest): Promise<PowerBIEmbedTokenAuthenticationResponseModel> {
        const response = await this.powerBIEmbedTokensGetByIdRaw(requestParameters);
        return await response.value();
    }

}
