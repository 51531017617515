// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PowerBIEmbedTokenReportInformation,
    PowerBIEmbedTokenReportInformationFromJSON,
    PowerBIEmbedTokenReportInformationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PowerBIEmbedTokenAuthenticationResponseModel
 */
export interface PowerBIEmbedTokenAuthenticationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PowerBIEmbedTokenAuthenticationResponseModel
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIEmbedTokenAuthenticationResponseModel
     */
    workspaceId?: string;
    /**
     * 
     * @type {Array<PowerBIEmbedTokenReportInformation>}
     * @memberof PowerBIEmbedTokenAuthenticationResponseModel
     */
    reports?: Array<PowerBIEmbedTokenReportInformation>;
}

export function PowerBIEmbedTokenAuthenticationResponseModelFromJSON(json: any): PowerBIEmbedTokenAuthenticationResponseModel {
    return {
        'token': !exists(json, 'token') ? undefined : json['token'],
        'workspaceId': !exists(json, 'workspaceId') ? undefined : json['workspaceId'],
        'reports': !exists(json, 'reports') ? undefined : (json['reports'] as Array<any>).map(PowerBIEmbedTokenReportInformationFromJSON),
    };
}

export function PowerBIEmbedTokenAuthenticationResponseModelToJSON(value?: PowerBIEmbedTokenAuthenticationResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'token': value.token,
        'workspaceId': value.workspaceId,
        'reports': value.reports === undefined ? undefined : (value.reports as Array<any>).map(PowerBIEmbedTokenReportInformationToJSON),
    };
}


