// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LeadsResponseModel,
    LeadsResponseModelFromJSON,
    LeadsResponseModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
} from '../models';

export interface DealershipLeadsSearchAsyncRequest {
    dealershipid: string;
    query?: string;
    queryType?: DealershipLeadsSearchAsyncQueryTypeEnum;
    filter?: string;
    skip?: number;
    take?: number;
    includeTotal?: boolean;
    includeExternalLeads?: boolean;
}

/**
 * no description
 */
export class DealershipLeadsApi extends runtime.BaseAPI {

    /**
     */
    async dealershipLeadsSearchAsyncRaw(requestParameters: DealershipLeadsSearchAsyncRequest): Promise<runtime.ApiResponse<LeadsResponseModel>> {
        if (requestParameters.dealershipid === null || requestParameters.dealershipid === undefined) {
            throw new runtime.RequiredError('dealershipid','Required parameter requestParameters.dealershipid was null or undefined when calling dealershipLeadsSearchAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.includeTotal !== undefined) {
            queryParameters['includeTotal'] = requestParameters.includeTotal;
        }

        if (requestParameters.includeExternalLeads !== undefined) {
            queryParameters['includeExternalLeads'] = requestParameters.includeExternalLeads;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/dealerships/{dealershipid}/leads`.replace(`{${"dealershipid"}}`, encodeURIComponent(String(requestParameters.dealershipid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeadsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async dealershipLeadsSearchAsync(requestParameters: DealershipLeadsSearchAsyncRequest): Promise<LeadsResponseModel> {
        const response = await this.dealershipLeadsSearchAsyncRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum DealershipLeadsSearchAsyncQueryTypeEnum {
    Unknown = 'Unknown',
    Simple = 'Simple',
    Full = 'Full'
}
