// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserNameResponseModel
 */
export interface UserNameResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UserNameResponseModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNameResponseModel
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNameResponseModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNameResponseModel
     */
    lastName?: string;
}

export function UserNameResponseModelFromJSON(json: any): UserNameResponseModel {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function UserNameResponseModelToJSON(value?: UserNameResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'username': value.username,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


