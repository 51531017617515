// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DealershipModel,
    DealershipModelFromJSON,
    DealershipModelToJSON,
    PhoneNumberResponseModel,
    PhoneNumberResponseModelFromJSON,
    PhoneNumberResponseModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
    UsernameResponseModel,
    UsernameResponseModelFromJSON,
    UsernameResponseModelToJSON,
} from '../models';

export interface UsersGetDealershipsAsyncRequest {
    userid: string;
}

export interface UsersGetUserByUsernameAsyncRequest {
    username: string;
}

export interface UsersGetUserPhoneVerificationAsyncRequest {
    phonenumber: string;
    dealershipid?: string;
}

/**
 * no description
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async usersGetDealershipsAsyncRaw(requestParameters: UsersGetDealershipsAsyncRequest): Promise<runtime.ApiResponse<Array<DealershipModel>>> {
        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling usersGetDealershipsAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/users/{userid}/dealerships`.replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealershipModelFromJSON));
    }

    /**
     */
    async usersGetDealershipsAsync(requestParameters: UsersGetDealershipsAsyncRequest): Promise<Array<DealershipModel>> {
        const response = await this.usersGetDealershipsAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async usersGetUserByUsernameAsyncRaw(requestParameters: UsersGetUserByUsernameAsyncRequest): Promise<runtime.ApiResponse<UsernameResponseModel>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling usersGetUserByUsernameAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsernameResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async usersGetUserByUsernameAsync(requestParameters: UsersGetUserByUsernameAsyncRequest): Promise<UsernameResponseModel> {
        const response = await this.usersGetUserByUsernameAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async usersGetUserPhoneVerificationAsyncRaw(requestParameters: UsersGetUserPhoneVerificationAsyncRequest): Promise<runtime.ApiResponse<PhoneNumberResponseModel>> {
        if (requestParameters.phonenumber === null || requestParameters.phonenumber === undefined) {
            throw new runtime.RequiredError('phonenumber','Required parameter requestParameters.phonenumber was null or undefined when calling usersGetUserPhoneVerificationAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.phonenumber !== undefined) {
            queryParameters['phonenumber'] = requestParameters.phonenumber;
        }

        if (requestParameters.dealershipid !== undefined) {
            queryParameters['dealershipid'] = requestParameters.dealershipid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/users/phone-verification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneNumberResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async usersGetUserPhoneVerificationAsync(requestParameters: UsersGetUserPhoneVerificationAsyncRequest): Promise<PhoneNumberResponseModel> {
        const response = await this.usersGetUserPhoneVerificationAsyncRaw(requestParameters);
        return await response.value();
    }

}
