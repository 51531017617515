/**
 * returns true if its a valid email, false otherwise
 * @param {string} value - email string input
 * @return {boolean} - returns true if its a valid email, false otherwise
 */
const isValidEmail = (value: string): boolean => (/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i).test(value);

/**
 * returns true if valid phone number
 * https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
 * @param {string} value
 * @return {boolean}
 */
// eslint-disable-next-line no-useless-escape
const isValidPhoneNumber = (value: string): boolean => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im).test(value);

/**
 * returns true if its a valid state abbreviation (e.g. VT), false otherwise
 * @param {string} value - state abbreviation string input
 * @return {boolean} - returns true if its a valid state abbreviation, false otherwise
 */
const isValidStateAbbreviation = (value: string | null | undefined): boolean => Boolean(value && /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/.test(value));

/**
 * returns true if n is numeric, returns false otherwise
 * SOURCE: https://stackoverflow.com/questions/9716468/pure-javascript-a-function-like-jquerys-isnumeric
 */
const isNumeric = (n: string | number): boolean => {
    if (typeof n === 'number') {
        return true;
    }
    return !Number.isNaN(Number.parseFloat(n)) && Number.isFinite(Number.parseFloat(n));
};

/**
 * @public
 */
const Validation = {
    isValidEmail,
    isValidPhoneNumber,
    isValidStateAbbreviation,
    isNumeric
};

export default Validation;
