// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticationResponseModel
 */
export interface AuthenticationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    authorization?: string;
}

export function AuthenticationResponseModelFromJSON(json: any): AuthenticationResponseModel {
    return {
        'authorization': !exists(json, 'authorization') ? undefined : json['authorization'],
    };
}

export function AuthenticationResponseModelToJSON(value?: AuthenticationResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'authorization': value.authorization,
    };
}


