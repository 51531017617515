/* eslint-disable no-undefined */
import * as R from 'ramda';

/**
 * takes a string and converts it to USD format i.e. '20" '$20.00;
 * @param {string|number} value - value to convert
 * @returns {string} - formatted string
 */
const convertToUSDFormat = (value: string | number): string => {
    if (typeof value === 'string' && Number.isNaN(parseFloat(value) || Number.isNaN(value))) {
        return '$0.00';
    }
    // Create our number formatter.
    const formatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
        // the default value for minimumFractionDigits depends on the currency
    });
    return formatter.format(Number(value));
};

/**
 * returns "500" as "500.00"
 * @param {string|number} value
 * @returns {string} value with 2 decimal places
 */
const convertToTwoDecimalPlaces = (value: string | number): string => {
    const formatter = Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(Number(value));
};

/**
 * converts from camel case i.e. "thisIsCamelCase" to "This Is Camel Case"
 * @param {string} value - string to convert
 * @returns {string|null} - converted String
 */
const convertFromCamelCaseToTitleCase = (value: string | null | undefined): string | null => (value ? R.concat(R.toUpper(R.slice(0, 1, value)), R.replace(/([A-Z])/g, ' $1', R.slice(1, value.length, value))) : null);

/**
 * converts to title case i.e. "hello this is words" to "Hello This Is Words"
 * @param {string} value - string to convert
 * @returns {string|null} - string to convert
 */
const convertWordsToTitleCase = (value: string | null | undefined): string | null => (value ? value.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : null);

/**
 * converts from underscore to space case i.e. "hello_this_is_underscore" to "hello this is underscore"
 * @param {string} value - string to convert
 * @returns {string|null} - string to convert
 */
const convertFromUnderscore = (value: string | null | undefined): string | null => (value ? R.replace(/_/g, ' ', value) : null);

/**
 * converts from pascal case to title case i.e. "HelloThisIsPascal" to "Hello This Is Pascal"
 * @param {string|number} value - string to convert
 * @returns {string|null} - string to convert
 */
const convertFromPascalCaseToTitleCase = (value: string | null | undefined): string | null => (value ? R.trim(R.replace(/([A-Z])/g, ' $1', value)) : null);

const formatPhoneNumber = (phoneNumber: string | number | null | undefined): string | null => {
    const stringInput = String(R.defaultTo('', phoneNumber)); // coerce to String in case it's a number
    const input: string = stringInput.replace(/(\D)/g, '');

    if (input.length === 0) {
        return null;
    }

    const phoneRegex = RegExp(/(\d?)(\d{3})(\d{3})(\d{4})$/);
    const match = phoneRegex.exec(input);

    if (!match || match.length < 5) {
        return null;
    }

    const [, countryCode, areaCode, exchangeCode, lastFour] = match;
    return `${countryCode && `+${countryCode} `}(${areaCode}) ${exchangeCode}-${lastFour}`;
};

/**
 * @public
 */
const StringHelpers = {
    convertToUSDFormat,
    convertToTwoDecimalPlaces,
    convertFromCamelCaseToTitleCase,
    convertWordsToTitleCase,
    convertFromUnderscore,
    convertFromPascalCaseToTitleCase,
    formatPhoneNumber
};

export default StringHelpers;
