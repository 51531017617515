// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PowerBIReportResponseModel,
    PowerBIReportResponseModelFromJSON,
    PowerBIReportResponseModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface PowerBIReportsResponseModel
 */
export interface PowerBIReportsResponseModel {
    /**
     * 
     * @type {Array<PowerBIReportResponseModel>}
     * @memberof PowerBIReportsResponseModel
     */
    powerBIReports?: Array<PowerBIReportResponseModel>;
}

export function PowerBIReportsResponseModelFromJSON(json: any): PowerBIReportsResponseModel {
    return {
        'powerBIReports': !exists(json, 'powerBIReports') ? undefined : (json['powerBIReports'] as Array<any>).map(PowerBIReportResponseModelFromJSON),
    };
}

export function PowerBIReportsResponseModelToJSON(value?: PowerBIReportsResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'powerBIReports': value.powerBIReports === undefined ? undefined : (value.powerBIReports as Array<any>).map(PowerBIReportResponseModelToJSON),
    };
}


