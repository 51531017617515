// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerBIEmbedTokenReportInformation
 */
export interface PowerBIEmbedTokenReportInformation {
    /**
     * 
     * @type {string}
     * @memberof PowerBIEmbedTokenReportInformation
     */
    reportId?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIEmbedTokenReportInformation
     */
    reportName?: string;
}

export function PowerBIEmbedTokenReportInformationFromJSON(json: any): PowerBIEmbedTokenReportInformation {
    return {
        'reportId': !exists(json, 'reportId') ? undefined : json['reportId'],
        'reportName': !exists(json, 'reportName') ? undefined : json['reportName'],
    };
}

export function PowerBIEmbedTokenReportInformationToJSON(value?: PowerBIEmbedTokenReportInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'reportId': value.reportId,
        'reportName': value.reportName,
    };
}


