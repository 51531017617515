// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConsumerDealAgentModel,
    ConsumerDealAgentModelFromJSON,
    ConsumerDealAgentModelToJSON,
    ConsumerDealDealershipModel,
    ConsumerDealDealershipModelFromJSON,
    ConsumerDealDealershipModelToJSON,
    ConsumerWorkflowStatusModel,
    ConsumerWorkflowStatusModelFromJSON,
    ConsumerWorkflowStatusModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConsumerDealModel
 */
export interface ConsumerDealModel {
    /**
     * 
     * @type {string}
     * @memberof ConsumerDealModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ConsumerDealModel
     */
    legacyId: number;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDealModel
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDealModel
     */
    type: ConsumerDealModelTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ConsumerDealModel
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDealModel
     */
    status: ConsumerDealModelStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof ConsumerDealModel
     */
    statusDate: Date;
    /**
     * 
     * @type {ConsumerDealDealershipModel}
     * @memberof ConsumerDealModel
     */
    dealership: ConsumerDealDealershipModel;
    /**
     * 
     * @type {ConsumerDealAgentModel}
     * @memberof ConsumerDealModel
     */
    agent?: ConsumerDealAgentModel;
    /**
     * 
     * @type {Array<ConsumerWorkflowStatusModel>}
     * @memberof ConsumerDealModel
     */
    statusHistory?: Array<ConsumerWorkflowStatusModel>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConsumerDealModel
     */
    additionalProperties?: { [key: string]: object; };
}

export function ConsumerDealModelFromJSON(json: any): ConsumerDealModel {
    return {
        'id': json['id'],
        'legacyId': json['legacyId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'type': json['type'],
        'created': new Date(json['created']),
        'status': json['status'],
        'statusDate': new Date(json['statusDate']),
        'dealership': ConsumerDealDealershipModelFromJSON(json['dealership']),
        'agent': !exists(json, 'agent') ? undefined : ConsumerDealAgentModelFromJSON(json['agent']),
        'statusHistory': !exists(json, 'statusHistory') ? undefined : (json['statusHistory'] as Array<any>).map(ConsumerWorkflowStatusModelFromJSON),
        'additionalProperties': !exists(json, 'additionalProperties') ? undefined : json['additionalProperties'],
    };
}

export function ConsumerDealModelToJSON(value?: ConsumerDealModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'legacyId': value.legacyId,
        'parentId': value.parentId,
        'type': value.type,
        'created': value.created.toISOString(),
        'status': value.status,
        'statusDate': value.statusDate.toISOString(),
        'dealership': ConsumerDealDealershipModelToJSON(value.dealership),
        'agent': ConsumerDealAgentModelToJSON(value.agent),
        'statusHistory': value.statusHistory === undefined ? undefined : (value.statusHistory as Array<any>).map(ConsumerWorkflowStatusModelToJSON),
        'additionalProperties': value.additionalProperties,
    };
}

/**
* @export
* @enum {string}
*/
export enum ConsumerDealModelTypeEnum {
    Unknown = 'Unknown',
    Insurance = 'Insurance',
    Finance = 'Finance',
    FinanceOnly = 'FinanceOnly',
    FastPass = 'FastPass',
    RapidRates = 'RapidRates'
}
/**
* @export
* @enum {string}
*/
export enum ConsumerDealModelStatusEnum {
    Unknown = 'Unknown',
    New = 'New',
    TermsAccepted = 'TermsAccepted',
    PresentedQuotes = 'PresentedQuotes',
    ConnectedWithAgent = 'ConnectedWithAgent',
    DiscussedRates = 'DiscussedRates',
    VerifiedDrivingRecord = 'VerifiedDrivingRecord',
    SoldPolicy = 'SoldPolicy'
}


