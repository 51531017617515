// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticationResponseModel,
    AuthenticationResponseModelFromJSON,
    AuthenticationResponseModelToJSON,
    LoginDeviceRequestModel,
    LoginDeviceRequestModelFromJSON,
    LoginDeviceRequestModelToJSON,
    LoginRequestModel,
    LoginRequestModelFromJSON,
    LoginRequestModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
    UserLoginResponseModel,
    UserLoginResponseModelFromJSON,
    UserLoginResponseModelToJSON,
} from '../models';

export interface AuthenticationsLoginRequest {
    setCookie?: boolean;
    loginRequestModel?: LoginRequestModel;
}

export interface AuthenticationsLoginDeviceRequest {
    setCookie?: boolean;
    loginDeviceRequestModel?: LoginDeviceRequestModel;
}

export interface AuthenticationsPutRequest {
    setCookie?: boolean;
}

/**
 * no description
 */
export class AuthenticationsApi extends runtime.BaseAPI {

    /**
     */
    async authenticationsLoginRaw(requestParameters: AuthenticationsLoginRequest): Promise<runtime.ApiResponse<UserLoginResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.setCookie !== undefined) {
            queryParameters['setCookie'] = requestParameters.setCookie;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestModelToJSON(requestParameters.loginRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async authenticationsLogin(requestParameters: AuthenticationsLoginRequest): Promise<UserLoginResponseModel> {
        const response = await this.authenticationsLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async authenticationsLoginDeviceRaw(requestParameters: AuthenticationsLoginDeviceRequest): Promise<runtime.ApiResponse<UserLoginResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.setCookie !== undefined) {
            queryParameters['setCookie'] = requestParameters.setCookie;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/loginDevice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDeviceRequestModelToJSON(requestParameters.loginDeviceRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async authenticationsLoginDevice(requestParameters: AuthenticationsLoginDeviceRequest): Promise<UserLoginResponseModel> {
        const response = await this.authenticationsLoginDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async authenticationsLoginWithCookieRaw(): Promise<runtime.ApiResponse<UserLoginResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/login-with-cookie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async authenticationsLoginWithCookie(): Promise<UserLoginResponseModel> {
        const response = await this.authenticationsLoginWithCookieRaw();
        return await response.value();
    }

    /**
     */
    async authenticationsLogoutRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authenticationsLogout(): Promise<void> {
        await this.authenticationsLogoutRaw();
    }

    /**
     */
    async authenticationsPutRaw(requestParameters: AuthenticationsPutRequest): Promise<runtime.ApiResponse<AuthenticationResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.setCookie !== undefined) {
            queryParameters['setCookie'] = requestParameters.setCookie;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/authentications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async authenticationsPut(requestParameters: AuthenticationsPutRequest): Promise<AuthenticationResponseModel> {
        const response = await this.authenticationsPutRaw(requestParameters);
        return await response.value();
    }

}
