// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdditionalIncomeModel
 */
export interface AdditionalIncomeModel {
    /**
     * 
     * @type {string}
     * @memberof AdditionalIncomeModel
     */
    incomeSource?: string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalIncomeModel
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalIncomeModel
     */
    paymentFrequency?: AdditionalIncomeModelPaymentFrequencyEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AdditionalIncomeModel
     */
    additionalProperties?: { [key: string]: object; };
}

export function AdditionalIncomeModelFromJSON(json: any): AdditionalIncomeModel {
    return {
        'incomeSource': !exists(json, 'incomeSource') ? undefined : json['incomeSource'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'paymentFrequency': !exists(json, 'paymentFrequency') ? undefined : json['paymentFrequency'],
        'additionalProperties': !exists(json, 'additionalProperties') ? undefined : json['additionalProperties'],
    };
}

export function AdditionalIncomeModelToJSON(value?: AdditionalIncomeModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'incomeSource': value.incomeSource,
        'amount': value.amount,
        'paymentFrequency': value.paymentFrequency,
        'additionalProperties': value.additionalProperties,
    };
}

/**
* @export
* @enum {string}
*/
export enum AdditionalIncomeModelPaymentFrequencyEnum {
    Unknown = 'Unknown',
    Monthly = 'Monthly',
    Weekly = 'Weekly',
    Annually = 'Annually'
}


