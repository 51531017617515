// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserComplianceModel
 */
export interface UserComplianceModel {
    /**
     * 
     * @type {string}
     * @memberof UserComplianceModel
     */
    agreementType?: UserComplianceModelAgreementTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserComplianceModel
     */
    isComplete?: boolean;
}

export function UserComplianceModelFromJSON(json: any): UserComplianceModel {
    return {
        'agreementType': !exists(json, 'agreementType') ? undefined : json['agreementType'],
        'isComplete': !exists(json, 'isComplete') ? undefined : json['isComplete'],
    };
}

export function UserComplianceModelToJSON(value?: UserComplianceModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'agreementType': value.agreementType,
        'isComplete': value.isComplete,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserComplianceModelAgreementTypeEnum {
    Unknown = 'Unknown',
    MarketplaceTermsOfService = 'MarketplaceTermsOfService',
    MarketplaceTraining = 'MarketplaceTraining',
    MarketplaceAssessment = 'MarketplaceAssessment',
    MarketplaceFastPassDemoLead = 'MarketplaceFastPassDemoLead',
    MarketplacePreferenceV1 = 'MarketplacePreferenceV1',
    MarketplacePreferenceV2 = 'MarketplacePreferenceV2',
    AdminTermsOfService = 'AdminTermsOfService',
    DealershipOnboardingTermsOfService = 'DealershipOnboardingTermsOfService',
    DealershipOnboardingTraining = 'DealershipOnboardingTraining',
    DealershipOnboardingAssessment = 'DealershipOnboardingAssessment'
}


