// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserServiceAgreementModel
 */
export interface UserServiceAgreementModel {
    /**
     * 
     * @type {string}
     * @memberof UserServiceAgreementModel
     */
    agreementType?: UserServiceAgreementModelAgreementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserServiceAgreementModel
     */
    version?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserServiceAgreementModel
     */
    agreementDate?: Date;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserServiceAgreementModel
     */
    details?: { [key: string]: string; };
}

export function UserServiceAgreementModelFromJSON(json: any): UserServiceAgreementModel {
    return {
        'agreementType': !exists(json, 'agreementType') ? undefined : json['agreementType'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'agreementDate': !exists(json, 'agreementDate') ? undefined : new Date(json['agreementDate']),
        'details': !exists(json, 'details') ? undefined : json['details'],
    };
}

export function UserServiceAgreementModelToJSON(value?: UserServiceAgreementModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'agreementType': value.agreementType,
        'version': value.version,
        'agreementDate': value.agreementDate === undefined ? undefined : value.agreementDate.toISOString(),
        'details': value.details,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserServiceAgreementModelAgreementTypeEnum {
    Unknown = 'Unknown',
    MarketplaceTermsOfService = 'MarketplaceTermsOfService',
    MarketplaceTraining = 'MarketplaceTraining',
    MarketplaceAssessment = 'MarketplaceAssessment',
    MarketplaceFastPassDemoLead = 'MarketplaceFastPassDemoLead',
    MarketplacePreferenceV1 = 'MarketplacePreferenceV1',
    MarketplacePreferenceV2 = 'MarketplacePreferenceV2',
    AdminTermsOfService = 'AdminTermsOfService',
    DealershipOnboardingTermsOfService = 'DealershipOnboardingTermsOfService',
    DealershipOnboardingTraining = 'DealershipOnboardingTraining',
    DealershipOnboardingAssessment = 'DealershipOnboardingAssessment'
}


