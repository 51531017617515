// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadAddressModel
 */
export interface LeadAddressModel {
    /**
     * 
     * @type {string}
     * @memberof LeadAddressModel
     */
    streetAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAddressModel
     */
    addressType: LeadAddressModelAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LeadAddressModel
     */
    streetAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAddressModel
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAddressModel
     */
    state?: LeadAddressModelStateEnum;
    /**
     * 
     * @type {string}
     * @memberof LeadAddressModel
     */
    zipCode?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LeadAddressModel
     */
    additionalProperties?: { [key: string]: object; };
}

export function LeadAddressModelFromJSON(json: any): LeadAddressModel {
    return {
        'streetAddress2': !exists(json, 'streetAddress2') ? undefined : json['streetAddress2'],
        'addressType': json['addressType'],
        'streetAddress': !exists(json, 'streetAddress') ? undefined : json['streetAddress'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'additionalProperties': !exists(json, 'additionalProperties') ? undefined : json['additionalProperties'],
    };
}

export function LeadAddressModelToJSON(value?: LeadAddressModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'streetAddress2': value.streetAddress2,
        'addressType': value.addressType,
        'streetAddress': value.streetAddress,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'additionalProperties': value.additionalProperties,
    };
}

/**
* @export
* @enum {string}
*/
export enum LeadAddressModelAddressTypeEnum {
    Unspecified = 'Unspecified',
    Home = 'Home',
    Office = 'Office',
    Garaging = 'Garaging',
    Prior = 'Prior',
    Property = 'Property'
}
/**
* @export
* @enum {string}
*/
export enum LeadAddressModelStateEnum {
    Unknown = 'Unknown',
    AL = 'AL',
    AK = 'AK',
    AR = 'AR',
    AZ = 'AZ',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DC = 'DC',
    DE = 'DE',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    IA = 'IA',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    MA = 'MA',
    MD = 'MD',
    ME = 'ME',
    MI = 'MI',
    MN = 'MN',
    MO = 'MO',
    MS = 'MS',
    MT = 'MT',
    NC = 'NC',
    ND = 'ND',
    NE = 'NE',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NV = 'NV',
    NY = 'NY',
    OK = 'OK',
    OH = 'OH',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VA = 'VA',
    VT = 'VT',
    WA = 'WA',
    WI = 'WI',
    WV = 'WV',
    WY = 'WY'
}


