// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerBIReportCreateRequestModel
 */
export interface PowerBIReportCreateRequestModel {
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportCreateRequestModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportCreateRequestModel
     */
    reportId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportCreateRequestModel
     */
    roles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportCreateRequestModel
     */
    organizationIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportCreateRequestModel
     */
    organizationTypes: Array<string>;
}

export function PowerBIReportCreateRequestModelFromJSON(json: any): PowerBIReportCreateRequestModel {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'reportId': json['reportId'],
        'roles': json['roles'],
        'organizationIds': json['organizationIds'],
        'organizationTypes': json['organizationTypes'],
    };
}

export function PowerBIReportCreateRequestModelToJSON(value?: PowerBIReportCreateRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'reportId': value.reportId,
        'roles': value.roles,
        'organizationIds': value.organizationIds,
        'organizationTypes': value.organizationTypes,
    };
}


