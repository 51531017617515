// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HomeLeadRequestModel,
    HomeLeadRequestModelFromJSON,
    HomeLeadRequestModelToJSON,
    HomeLeadResponseModel,
    HomeLeadResponseModelFromJSON,
    HomeLeadResponseModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
} from '../models';

export interface RealtorAgentUsersPostHomeLeadAsyncRequest {
    organizationid: string;
    userid: string;
    homeLeadRequestModel?: HomeLeadRequestModel;
}

/**
 * no description
 */
export class RealtorAgentUsersApi extends runtime.BaseAPI {

    /**
     */
    async realtorAgentUsersPostHomeLeadAsyncRaw(requestParameters: RealtorAgentUsersPostHomeLeadAsyncRequest): Promise<runtime.ApiResponse<HomeLeadResponseModel>> {
        if (requestParameters.organizationid === null || requestParameters.organizationid === undefined) {
            throw new runtime.RequiredError('organizationid','Required parameter requestParameters.organizationid was null or undefined when calling realtorAgentUsersPostHomeLeadAsync.');
        }

        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling realtorAgentUsersPostHomeLeadAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/realtor-agents/{organizationid}/users/{userid}`.replace(`{${"organizationid"}}`, encodeURIComponent(String(requestParameters.organizationid))).replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HomeLeadRequestModelToJSON(requestParameters.homeLeadRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HomeLeadResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async realtorAgentUsersPostHomeLeadAsync(requestParameters: RealtorAgentUsersPostHomeLeadAsyncRequest): Promise<HomeLeadResponseModel> {
        const response = await this.realtorAgentUsersPostHomeLeadAsyncRaw(requestParameters);
        return await response.value();
    }

}
