/* eslint-disable no-undefined */
import moment, { MomentInput } from 'moment';
import DateTimeFormats from './DateTimeFormats';

/**
 * Given a datetime input, format it as an ISO 8601 string with the timezone offset
 */
const toISOString = (dateTime?: MomentInput | null, inputFormat?: string): string | null => {
    // zero is valid, other falsy values are not
    if (dateTime !== 0 && !dateTime) {
        return null;
    }

    const m = moment(dateTime, inputFormat);
    if (!m.isValid()) {
        // eslint-disable-next-line no-console
        console.error(`Invalid input: ${m.inspect()}`);
        return null;
    }

    // https://momentjs.com/docs/#/displaying/as-iso-string/
    return m.toISOString(true);
};

/**
 * Given a datetime input, convert it to the corresponding datetime in the UTC timezone and format it
 */
const toUTCString = (dateTime?: MomentInput | null, outputFormat: string = DateTimeFormats.MDY_HM_AMPM, inputFormat?: string, fromUTC = false): string | null => {
    // zero is valid, other falsy values are not
    if (dateTime !== 0 && !dateTime) {
        return null;
    }

    const m = fromUTC ? moment.utc(dateTime, inputFormat) : moment(dateTime, inputFormat);
    if (!m.isValid()) {
        // eslint-disable-next-line no-console
        console.error(`Invalid input: ${m.inspect()}`);
        return null;
    }

    const momentObject = m.utc();
    if (outputFormat === DateTimeFormats.ISO_8601) {
        return momentObject.toISOString(true);
    }

    return momentObject.format(outputFormat);
};

/**
 * Given a datetime input, convert it to the corresponding datetime in the client's timezone and format it
 */
const toLocalString = (dateTime: MomentInput | null, outputFormat: string = DateTimeFormats.MDY_HM_AMPM, inputFormat?: string, fromLocal = false): string | null => {
    // zero is valid, other falsy values are not
    if (dateTime !== 0 && !dateTime) {
        return null;
    }

    const m = fromLocal ? moment(dateTime, inputFormat) : moment.utc(dateTime, inputFormat);
    if (!m.isValid()) {
        // eslint-disable-next-line no-console
        console.error(`Invalid input: ${m.inspect()}`);
        return null;
    }

    const momentObject = m.local();
    if (outputFormat === DateTimeFormats.ISO_8601) {
        return momentObject.toISOString(true);
    }

    return momentObject.format(outputFormat);
};

/**
 * take only the YYYY-MM-DD part of the ISO string, without using moment to avoid making the date relative to the client
 */
const parseDateFromISOString = (isoString: string | null | undefined): string | undefined => (isoString ? isoString.split('T')[0] : undefined);

/**
 * take only the YYYY-MM-DD part of the Date's ISO string because the api package doesn't understand how a Date is different from a DateTime
 */
const parseDateFromDateObject = (date?: Date): string | undefined => (date ? parseDateFromISOString(date.toISOString()) : undefined);

/**
 * convert an isoString for a Date (not DateTime or Time) to a Date Object for the api client consumption
 * *** this is only required because openapi generated JS clients use the JS Date Object and don't preserve the offset when formatting as an ISO string***
 */
const fromIsoStringToApiClientDateOnly = (isoString: string | null | undefined): Date | undefined => {
    const dateString = parseDateFromISOString(isoString);
    return dateString === undefined ? undefined : moment.utc(dateString).toDate();
};

/**
 * @public
 */
const DateTimeHelpers = {
    toISOString,
    toUTCString,
    toLocalString,
    parseDateFromISOString,
    parseDateFromDateObject,
    fromIsoStringToApiClientDateOnly
};

export default DateTimeHelpers;
