// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalIncomeModel,
    AdditionalIncomeModelFromJSON,
    AdditionalIncomeModelToJSON,
    ConsumerAddressModel,
    ConsumerAddressModelFromJSON,
    ConsumerAddressModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface FinanceAggregatedFinanceApplicantModel
 */
export interface FinanceAggregatedFinanceApplicantModel {
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    consumerId?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    mobilePhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    maritalStatus?: FinanceAggregatedFinanceApplicantModelMaritalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    driversLicenseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    socialSecurityNumberLastFour?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    gender?: FinanceAggregatedFinanceApplicantModelGenderEnum;
    /**
     * 
     * @type {ConsumerAddressModel}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    homeAddress?: ConsumerAddressModel;
    /**
     * 
     * @type {ConsumerAddressModel}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    garagingAddress?: ConsumerAddressModel;
    /**
     * 
     * @type {number}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    timeAtResidenceMonths?: number;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    residenceOwnershipStatus?: FinanceAggregatedFinanceApplicantModelResidenceOwnershipStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    mortgageCompanyOrLandlord?: string;
    /**
     * 
     * @type {number}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    monthlyMortgageOrRent?: number;
    /**
     * 
     * @type {ConsumerAddressModel}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    priorHomeAddress?: ConsumerAddressModel;
    /**
     * 
     * @type {number}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    timeAtPriorResidenceMonths?: number;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    businessType?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    businessEnterpriseType?: FinanceAggregatedFinanceApplicantModelBusinessEnterpriseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    businessYears?: number;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    employer?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    employerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    employmentStatus?: FinanceAggregatedFinanceApplicantModelEmploymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    paymentFrequency?: FinanceAggregatedFinanceApplicantModelPaymentFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    monthsEmployed?: number;
    /**
     * 
     * @type {number}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    grossIncome?: number;
    /**
     * 
     * @type {Array<AdditionalIncomeModel>}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    additionalIncomes?: Array<AdditionalIncomeModel>;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    priorEmployer?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    priorJobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    priorEmployerPhone?: string;
    /**
     * 
     * @type {number}
     * @memberof FinanceAggregatedFinanceApplicantModel
     */
    priorMonthsEmployed?: number;
}

export function FinanceAggregatedFinanceApplicantModelFromJSON(json: any): FinanceAggregatedFinanceApplicantModel {
    return {
        'consumerId': !exists(json, 'consumerId') ? undefined : json['consumerId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'suffix': !exists(json, 'suffix') ? undefined : json['suffix'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : new Date(json['dateOfBirth']),
        'maritalStatus': !exists(json, 'maritalStatus') ? undefined : json['maritalStatus'],
        'driversLicenseNumber': !exists(json, 'driversLicenseNumber') ? undefined : json['driversLicenseNumber'],
        'socialSecurityNumberLastFour': !exists(json, 'socialSecurityNumberLastFour') ? undefined : json['socialSecurityNumberLastFour'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'homeAddress': !exists(json, 'homeAddress') ? undefined : ConsumerAddressModelFromJSON(json['homeAddress']),
        'garagingAddress': !exists(json, 'garagingAddress') ? undefined : ConsumerAddressModelFromJSON(json['garagingAddress']),
        'timeAtResidenceMonths': !exists(json, 'timeAtResidenceMonths') ? undefined : json['timeAtResidenceMonths'],
        'residenceOwnershipStatus': !exists(json, 'residenceOwnershipStatus') ? undefined : json['residenceOwnershipStatus'],
        'mortgageCompanyOrLandlord': !exists(json, 'mortgageCompanyOrLandlord') ? undefined : json['mortgageCompanyOrLandlord'],
        'monthlyMortgageOrRent': !exists(json, 'monthlyMortgageOrRent') ? undefined : json['monthlyMortgageOrRent'],
        'priorHomeAddress': !exists(json, 'priorHomeAddress') ? undefined : ConsumerAddressModelFromJSON(json['priorHomeAddress']),
        'timeAtPriorResidenceMonths': !exists(json, 'timeAtPriorResidenceMonths') ? undefined : json['timeAtPriorResidenceMonths'],
        'businessName': !exists(json, 'businessName') ? undefined : json['businessName'],
        'businessType': !exists(json, 'businessType') ? undefined : json['businessType'],
        'businessEnterpriseType': !exists(json, 'businessEnterpriseType') ? undefined : json['businessEnterpriseType'],
        'businessYears': !exists(json, 'businessYears') ? undefined : json['businessYears'],
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'employerPhone': !exists(json, 'employerPhone') ? undefined : json['employerPhone'],
        'employmentStatus': !exists(json, 'employmentStatus') ? undefined : json['employmentStatus'],
        'paymentFrequency': !exists(json, 'paymentFrequency') ? undefined : json['paymentFrequency'],
        'monthsEmployed': !exists(json, 'monthsEmployed') ? undefined : json['monthsEmployed'],
        'grossIncome': !exists(json, 'grossIncome') ? undefined : json['grossIncome'],
        'additionalIncomes': !exists(json, 'additionalIncomes') ? undefined : (json['additionalIncomes'] as Array<any>).map(AdditionalIncomeModelFromJSON),
        'priorEmployer': !exists(json, 'priorEmployer') ? undefined : json['priorEmployer'],
        'priorJobTitle': !exists(json, 'priorJobTitle') ? undefined : json['priorJobTitle'],
        'priorEmployerPhone': !exists(json, 'priorEmployerPhone') ? undefined : json['priorEmployerPhone'],
        'priorMonthsEmployed': !exists(json, 'priorMonthsEmployed') ? undefined : json['priorMonthsEmployed'],
    };
}

export function FinanceAggregatedFinanceApplicantModelToJSON(value?: FinanceAggregatedFinanceApplicantModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'consumerId': value.consumerId,
        'firstName': value.firstName,
        'middleName': value.middleName,
        'lastName': value.lastName,
        'suffix': value.suffix,
        'emailAddress': value.emailAddress,
        'mobilePhone': value.mobilePhone,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : value.dateOfBirth.toISOString(),
        'maritalStatus': value.maritalStatus,
        'driversLicenseNumber': value.driversLicenseNumber,
        'socialSecurityNumberLastFour': value.socialSecurityNumberLastFour,
        'gender': value.gender,
        'homeAddress': ConsumerAddressModelToJSON(value.homeAddress),
        'garagingAddress': ConsumerAddressModelToJSON(value.garagingAddress),
        'timeAtResidenceMonths': value.timeAtResidenceMonths,
        'residenceOwnershipStatus': value.residenceOwnershipStatus,
        'mortgageCompanyOrLandlord': value.mortgageCompanyOrLandlord,
        'monthlyMortgageOrRent': value.monthlyMortgageOrRent,
        'priorHomeAddress': ConsumerAddressModelToJSON(value.priorHomeAddress),
        'timeAtPriorResidenceMonths': value.timeAtPriorResidenceMonths,
        'businessName': value.businessName,
        'businessType': value.businessType,
        'businessEnterpriseType': value.businessEnterpriseType,
        'businessYears': value.businessYears,
        'occupation': value.occupation,
        'employer': value.employer,
        'employerPhone': value.employerPhone,
        'employmentStatus': value.employmentStatus,
        'paymentFrequency': value.paymentFrequency,
        'monthsEmployed': value.monthsEmployed,
        'grossIncome': value.grossIncome,
        'additionalIncomes': value.additionalIncomes === undefined ? undefined : (value.additionalIncomes as Array<any>).map(AdditionalIncomeModelToJSON),
        'priorEmployer': value.priorEmployer,
        'priorJobTitle': value.priorJobTitle,
        'priorEmployerPhone': value.priorEmployerPhone,
        'priorMonthsEmployed': value.priorMonthsEmployed,
    };
}

/**
* @export
* @enum {string}
*/
export enum FinanceAggregatedFinanceApplicantModelMaritalStatusEnum {
    Unspecified = 'Unspecified',
    Single = 'Single',
    Married = 'Married',
    Separated = 'Separated',
    Divorced = 'Divorced',
    Widowed = 'Widowed',
    DomesticPartner = 'DomesticPartner'
}
/**
* @export
* @enum {string}
*/
export enum FinanceAggregatedFinanceApplicantModelGenderEnum {
    Unspecified = 'Unspecified',
    Male = 'Male',
    Female = 'Female'
}
/**
* @export
* @enum {string}
*/
export enum FinanceAggregatedFinanceApplicantModelResidenceOwnershipStatusEnum {
    Unknown = 'Unknown',
    Owns = 'Owns',
    Rents = 'Rents',
    LivesWithParents = 'LivesWithParents',
    Other = 'Other',
    OwnsFreeAndClear = 'OwnsFreeAndClear'
}
/**
* @export
* @enum {string}
*/
export enum FinanceAggregatedFinanceApplicantModelBusinessEnterpriseTypeEnum {
    Unknown = 'Unknown',
    Corporation = 'Corporation',
    LLC = 'LLC',
    Partnership = 'Partnership',
    Proprietorship = 'Proprietorship'
}
/**
* @export
* @enum {string}
*/
export enum FinanceAggregatedFinanceApplicantModelEmploymentStatusEnum {
    Unknown = 'Unknown',
    Other = 'Other',
    Employed = 'Employed',
    SelfEmployed = 'SelfEmployed',
    Unemployed = 'Unemployed',
    Military = 'Military',
    Retired = 'Retired'
}
/**
* @export
* @enum {string}
*/
export enum FinanceAggregatedFinanceApplicantModelPaymentFrequencyEnum {
    Unknown = 'Unknown',
    Monthly = 'Monthly',
    Weekly = 'Weekly',
    Annually = 'Annually'
}


