/* eslint-disable no-extra-parens */
import * as R from 'ramda';

/**
 * Takes a list of elements and returns a new list of only truthy results.
 * Any element that is a function will be executed and if the result is a truthy value, it will be included.
 * Any falsy elements will be excluded.
 * @param {Array<*>} array - list of elements
 * @returns {Array<*>} - list of only truthy values
 */
const selectTruthyResults = <T> (array: Array<T>): Array<T> => {
    const list = ([] as Array<T>).concat(array);
    return list.reduce<Array<T>>((acc, element) => {
        const item = typeof element === 'function'
            ? element()
            : element;

        return item
            ? R.append(item, acc)
            : acc;
    }, []);
};

/**
 * Takes a list of elements and returns a new list of only truthy elements.
 * @param {Array<*>} array - list of elements
 * @returns {Array<*>} - list of only truthy elements
 */
const selectTruthyElements = <T> (array: Array<T>): Array<T> => {
    const list = ([] as Array<T>).concat(array);
    return list.reduce<Array<T>>((acc, element) => (
        element
            ? R.append(element, acc)
            : acc
    ), []);
};

/**
 * @public
 */
const ArrayHelpers = {
    selectTruthyResults,
    selectTruthyElements
};

export default ArrayHelpers;
