// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDeviceRequestModel
 */
export interface UserDeviceRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserDeviceRequestModel
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceRequestModel
     */
    description: string;
}

export function UserDeviceRequestModelFromJSON(json: any): UserDeviceRequestModel {
    return {
        'publicKey': json['publicKey'],
        'description': json['description'],
    };
}

export function UserDeviceRequestModelToJSON(value?: UserDeviceRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'publicKey': value.publicKey,
        'description': value.description,
    };
}


