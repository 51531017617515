// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Duration,
    DurationFromJSON,
    DurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConsumerAddressModel
 */
export interface ConsumerAddressModel {
    /**
     * 
     * @type {string}
     * @memberof ConsumerAddressModel
     */
    addressType: ConsumerAddressModelAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsumerAddressModel
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerAddressModel
     */
    streetAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerAddressModel
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerAddressModel
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerAddressModel
     */
    zipCode: string;
    /**
     * 
     * @type {Duration}
     * @memberof ConsumerAddressModel
     */
    timeAtAddress: Duration;
}

export function ConsumerAddressModelFromJSON(json: any): ConsumerAddressModel {
    return {
        'addressType': json['addressType'],
        'streetAddress': json['streetAddress'],
        'streetAddress2': !exists(json, 'streetAddress2') ? undefined : json['streetAddress2'],
        'city': json['city'],
        'state': json['state'],
        'zipCode': json['zipCode'],
        'timeAtAddress': DurationFromJSON(json['timeAtAddress']),
    };
}

export function ConsumerAddressModelToJSON(value?: ConsumerAddressModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'addressType': value.addressType,
        'streetAddress': value.streetAddress,
        'streetAddress2': value.streetAddress2,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'timeAtAddress': DurationToJSON(value.timeAtAddress),
    };
}

/**
* @export
* @enum {string}
*/
export enum ConsumerAddressModelAddressTypeEnum {
    Unspecified = 'Unspecified',
    Home = 'Home',
    Office = 'Office',
    Garaging = 'Garaging',
    Prior = 'Prior',
    Property = 'Property'
}


