// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FastPassCommunicationRequestModel,
    FastPassCommunicationRequestModelFromJSON,
    FastPassCommunicationRequestModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
} from '../models';

export interface CommunicationsSendFastPassQuotesTextAsyncRequest {
    fastPassCommunicationRequestModel?: FastPassCommunicationRequestModel;
}

export interface CommunicationsSendFastPassTextAsyncRequest {
    fastPassCommunicationRequestModel?: FastPassCommunicationRequestModel;
}

/**
 * no description
 */
export class CommunicationsApi extends runtime.BaseAPI {

    /**
     */
    async communicationsSendFastPassQuotesTextAsyncRaw(requestParameters: CommunicationsSendFastPassQuotesTextAsyncRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/communications/fastpass-quotes-text`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FastPassCommunicationRequestModelToJSON(requestParameters.fastPassCommunicationRequestModel),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async communicationsSendFastPassQuotesTextAsync(requestParameters: CommunicationsSendFastPassQuotesTextAsyncRequest): Promise<void> {
        await this.communicationsSendFastPassQuotesTextAsyncRaw(requestParameters);
    }

    /**
     */
    async communicationsSendFastPassTextAsyncRaw(requestParameters: CommunicationsSendFastPassTextAsyncRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/communications/fastpass-text`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FastPassCommunicationRequestModelToJSON(requestParameters.fastPassCommunicationRequestModel),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async communicationsSendFastPassTextAsync(requestParameters: CommunicationsSendFastPassTextAsyncRequest): Promise<void> {
        await this.communicationsSendFastPassTextAsyncRaw(requestParameters);
    }

}
