// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsernameResponseModel
 */
export interface UsernameResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UsernameResponseModel
     */
    username?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsernameResponseModel
     */
    isDeleted?: boolean;
}

export function UsernameResponseModelFromJSON(json: any): UsernameResponseModel {
    return {
        'username': !exists(json, 'username') ? undefined : json['username'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function UsernameResponseModelToJSON(value?: UsernameResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'isDeleted': value.isDeleted,
    };
}


