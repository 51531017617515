import * as R from 'ramda';
import { IDictionary } from '../types/BaseTypes';

const createDictionaryFromList = <T>(list: Array<T>, keyFunction: (listElement: T) => string): IDictionary<T> => R.indexBy(keyFunction, list);

/**
 * @public
 */
const Utilities = {
    createDictionaryFromList: createDictionaryFromList
};

export default Utilities;
