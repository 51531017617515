// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PowerBIReportResponse,
    PowerBIReportResponseFromJSON,
    PowerBIReportResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PowerBIReportsResponse
 */
export interface PowerBIReportsResponse {
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportsResponse
     */
    oDataContext?: string;
    /**
     * 
     * @type {Array<PowerBIReportResponse>}
     * @memberof PowerBIReportsResponse
     */
    reports?: Array<PowerBIReportResponse>;
}

export function PowerBIReportsResponseFromJSON(json: any): PowerBIReportsResponse {
    return {
        'oDataContext': !exists(json, 'oDataContext') ? undefined : json['oDataContext'],
        'reports': !exists(json, 'reports') ? undefined : (json['reports'] as Array<any>).map(PowerBIReportResponseFromJSON),
    };
}

export function PowerBIReportsResponseToJSON(value?: PowerBIReportsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'oDataContext': value.oDataContext,
        'reports': value.reports === undefined ? undefined : (value.reports as Array<any>).map(PowerBIReportResponseToJSON),
    };
}


