/**
 * Intended for use with the MomentJS library
 * Source: https://momentjs.com/docs/#/displaying/format/
 */

// time units
const HOUR_24 = 'HH'; // 00 01 ... 22 23
const HOUR = 'h'; // 1 2 ... 11 12
const MINUTE = 'mm'; // 00 01 ... 58 59
const SECOND = 'ss'; // 00 01 ... 58 59
const AMPM = 'A'; // AM PM

// date units
const YEAR = 'Y'; // 1970 1971 ... 9999 +10000 +10001
const MONTH = 'MM'; // 01 02 ... 11 12
const DAY = 'DD'; // 01 02 ... 30 31

// time formats
const HM = `${HOUR_24}:${MINUTE}`;
const HM_AMPM = `${HOUR}:${MINUTE} ${AMPM}`;
const HMS = `${HOUR_24}:${MINUTE}:${SECOND}`;
const HMS_AMPM = `${HOUR}:${MINUTE}:${SECOND} ${AMPM}`;

// date formats
const YMD = `${YEAR}/${MONTH}/${DAY}`;
const MDY = `${MONTH}/${DAY}/${YEAR}`;
const DMY = `${DAY}/${MONTH}/${YEAR}`;

// date time formats
const ISO_8601 = 'Y-MM-DD[T]HH:mm:ss.SSSZ'; // don't use the units defined above in case they change
const YMD_HM = `${YMD} ${HM}`;
const MDY_HM_AMPM = `${MDY} ${HM_AMPM}`;

/**
 * @public
 */
const DateTimeFormats = Object.freeze({
    HM,
    HM_AMPM,
    HMS,
    HMS_AMPM,
    YMD,
    MDY,
    DMY,
    ISO_8601,
    YMD_HM,
    MDY_HM_AMPM
});

export default DateTimeFormats;
