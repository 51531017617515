// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConsumerAddressModel,
    ConsumerAddressModelFromJSON,
    ConsumerAddressModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleModel
 */
export interface VehicleModel {
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    vin?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleModel
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    make: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    registeredOwnerKey: string;
    /**
     * 
     * @type {ConsumerAddressModel}
     * @memberof VehicleModel
     */
    registeredAddress: ConsumerAddressModel;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    coverage: VehicleModelCoverageEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModel
     */
    isBeingPurchased: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModel
     */
    isBeingPurchasedNew: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    ownershipType: VehicleModelOwnershipTypeEnum;
}

export function VehicleModelFromJSON(json: any): VehicleModel {
    return {
        'id': json['id'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'year': json['year'],
        'make': json['make'],
        'model': json['model'],
        'registeredOwnerKey': json['registeredOwnerKey'],
        'registeredAddress': ConsumerAddressModelFromJSON(json['registeredAddress']),
        'coverage': json['coverage'],
        'isBeingPurchased': json['isBeingPurchased'],
        'isBeingPurchasedNew': json['isBeingPurchasedNew'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'ownershipType': json['ownershipType'],
    };
}

export function VehicleModelToJSON(value?: VehicleModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'vin': value.vin,
        'year': value.year,
        'make': value.make,
        'model': value.model,
        'registeredOwnerKey': value.registeredOwnerKey,
        'registeredAddress': ConsumerAddressModelToJSON(value.registeredAddress),
        'coverage': value.coverage,
        'isBeingPurchased': value.isBeingPurchased,
        'isBeingPurchasedNew': value.isBeingPurchasedNew,
        'label': value.label,
        'ownershipType': value.ownershipType,
    };
}

/**
* @export
* @enum {string}
*/
export enum VehicleModelCoverageEnum {
    Unknown = 'Unknown',
    LiabilityOnly = 'LiabilityOnly',
    FullCoverage = 'FullCoverage',
    IndividuallySelected = 'IndividuallySelected'
}
/**
* @export
* @enum {string}
*/
export enum VehicleModelOwnershipTypeEnum {
    Unknown = 'Unknown',
    Financed = 'Financed',
    Leased = 'Leased',
    Owned = 'Owned'
}


