// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TemporaryTokenAuthenticationRequestModel,
    TemporaryTokenAuthenticationRequestModelFromJSON,
    TemporaryTokenAuthenticationRequestModelToJSON,
    TemporaryTokenAuthenticationResponseModel,
    TemporaryTokenAuthenticationResponseModelFromJSON,
    TemporaryTokenAuthenticationResponseModelToJSON,
} from '../models';

export interface TemporaryTokenAuthenticationsPostRequest {
    setCookie?: boolean;
    temporaryTokenAuthenticationRequestModel?: TemporaryTokenAuthenticationRequestModel;
}

/**
 * no description
 */
export class TemporaryTokenAuthenticationsApi extends runtime.BaseAPI {

    /**
     */
    async temporaryTokenAuthenticationsPostRaw(requestParameters: TemporaryTokenAuthenticationsPostRequest): Promise<runtime.ApiResponse<TemporaryTokenAuthenticationResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.setCookie !== undefined) {
            queryParameters['setCookie'] = requestParameters.setCookie;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/temporary-token-authentications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemporaryTokenAuthenticationRequestModelToJSON(requestParameters.temporaryTokenAuthenticationRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TemporaryTokenAuthenticationResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async temporaryTokenAuthenticationsPost(requestParameters: TemporaryTokenAuthenticationsPostRequest): Promise<TemporaryTokenAuthenticationResponseModel> {
        const response = await this.temporaryTokenAuthenticationsPostRaw(requestParameters);
        return await response.value();
    }

}
