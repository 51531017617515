// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealershipModel
 */
export interface DealershipModel {
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    zipCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealershipModel
     */
    oems?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DealershipModel
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealershipModel
     */
    areManualDealsAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    supportPhoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DealershipModel
     */
    isInsuranceVerificationEligible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealershipModel
     */
    financeDealsEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealershipModel
     */
    creditPageLegalText?: string;
}

export function DealershipModelFromJSON(json: any): DealershipModel {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'oems': !exists(json, 'oems') ? undefined : json['oems'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'areManualDealsAllowed': !exists(json, 'areManualDealsAllowed') ? undefined : json['areManualDealsAllowed'],
        'supportPhoneNumber': !exists(json, 'supportPhoneNumber') ? undefined : json['supportPhoneNumber'],
        'isInsuranceVerificationEligible': !exists(json, 'isInsuranceVerificationEligible') ? undefined : json['isInsuranceVerificationEligible'],
        'financeDealsEnabled': !exists(json, 'financeDealsEnabled') ? undefined : json['financeDealsEnabled'],
        'creditPageLegalText': !exists(json, 'creditPageLegalText') ? undefined : json['creditPageLegalText'],
    };
}

export function DealershipModelToJSON(value?: DealershipModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'oems': value.oems,
        'code': value.code,
        'isDeleted': value.isDeleted,
        'areManualDealsAllowed': value.areManualDealsAllowed,
        'supportPhoneNumber': value.supportPhoneNumber,
        'isInsuranceVerificationEligible': value.isInsuranceVerificationEligible,
        'financeDealsEnabled': value.financeDealsEnabled,
        'creditPageLegalText': value.creditPageLegalText,
    };
}


