// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface HomeLeadRequestModel
 */
export interface HomeLeadRequestModel {
    /**
     * 
     * @type {string}
     * @memberof HomeLeadRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof HomeLeadRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof HomeLeadRequestModel
     */
    gender?: HomeLeadRequestModelGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof HomeLeadRequestModel
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof HomeLeadRequestModel
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof HomeLeadRequestModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {Address}
     * @memberof HomeLeadRequestModel
     */
    address?: Address;
    /**
     * 
     * @type {Address}
     * @memberof HomeLeadRequestModel
     */
    propertyAddress?: Address;
    /**
     * 
     * @type {number}
     * @memberof HomeLeadRequestModel
     */
    squareFootage: number;
    /**
     * 
     * @type {number}
     * @memberof HomeLeadRequestModel
     */
    yearBuilt: number;
    /**
     * 
     * @type {string}
     * @memberof HomeLeadRequestModel
     */
    propertyType?: HomeLeadRequestModelPropertyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HomeLeadRequestModel
     */
    partnerId?: string;
}

export function HomeLeadRequestModelFromJSON(json: any): HomeLeadRequestModel {
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : new Date(json['dateOfBirth']),
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'propertyAddress': !exists(json, 'propertyAddress') ? undefined : AddressFromJSON(json['propertyAddress']),
        'squareFootage': json['squareFootage'],
        'yearBuilt': json['yearBuilt'],
        'propertyType': !exists(json, 'propertyType') ? undefined : json['propertyType'],
        'partnerId': !exists(json, 'partnerId') ? undefined : json['partnerId'],
    };
}

export function HomeLeadRequestModelToJSON(value?: HomeLeadRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'gender': value.gender,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : value.dateOfBirth.toISOString(),
        'mobilePhone': value.mobilePhone,
        'emailAddress': value.emailAddress,
        'address': AddressToJSON(value.address),
        'propertyAddress': AddressToJSON(value.propertyAddress),
        'squareFootage': value.squareFootage,
        'yearBuilt': value.yearBuilt,
        'propertyType': value.propertyType,
        'partnerId': value.partnerId,
    };
}

/**
* @export
* @enum {string}
*/
export enum HomeLeadRequestModelGenderEnum {
    Unspecified = 'Unspecified',
    Male = 'Male',
    Female = 'Female'
}
/**
* @export
* @enum {string}
*/
export enum HomeLeadRequestModelPropertyTypeEnum {
    Unknown = 'Unknown',
    Home = 'Home',
    Renters = 'Renters',
    Condo = 'Condo'
}


