// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginDeviceRequestModel
 */
export interface LoginDeviceRequestModel {
    /**
     * 
     * @type {string}
     * @memberof LoginDeviceRequestModel
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDeviceRequestModel
     */
    signature: string;
}

export function LoginDeviceRequestModelFromJSON(json: any): LoginDeviceRequestModel {
    return {
        'deviceId': json['deviceId'],
        'signature': json['signature'],
    };
}

export function LoginDeviceRequestModelToJSON(value?: LoginDeviceRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'deviceId': value.deviceId,
        'signature': value.signature,
    };
}


