// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLoginResponseModel
 */
export interface UserLoginResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseModel
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserLoginResponseModel
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseModel
     */
    authorization?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseModel
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseModel
     */
    mobilePhone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseModel
     */
    dealershipIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseModel
     */
    dealershipGroupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseModel
     */
    carrierIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseModel
     */
    fiAgencyIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseModel
     */
    partnerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseModel
     */
    folderIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseModel
     */
    roles?: Array<string>;
}

export function UserLoginResponseModelFromJSON(json: any): UserLoginResponseModel {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : new Date(json['createdAt']),
        'authorization': !exists(json, 'authorization') ? undefined : json['authorization'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'dealershipIds': !exists(json, 'dealershipIds') ? undefined : json['dealershipIds'],
        'dealershipGroupIds': !exists(json, 'dealershipGroupIds') ? undefined : json['dealershipGroupIds'],
        'carrierIds': !exists(json, 'carrierIds') ? undefined : json['carrierIds'],
        'fiAgencyIds': !exists(json, 'fiAgencyIds') ? undefined : json['fiAgencyIds'],
        'partnerIds': !exists(json, 'partnerIds') ? undefined : json['partnerIds'],
        'folderIds': !exists(json, 'folderIds') ? undefined : json['folderIds'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function UserLoginResponseModelToJSON(value?: UserLoginResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'authorization': value.authorization,
        'username': value.username,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'mobilePhone': value.mobilePhone,
        'dealershipIds': value.dealershipIds,
        'dealershipGroupIds': value.dealershipGroupIds,
        'carrierIds': value.carrierIds,
        'fiAgencyIds': value.fiAgencyIds,
        'partnerIds': value.partnerIds,
        'folderIds': value.folderIds,
        'roles': value.roles,
    };
}


