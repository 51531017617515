// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerBIReportUpdateRequestModel
 */
export interface PowerBIReportUpdateRequestModel {
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportUpdateRequestModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportUpdateRequestModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportUpdateRequestModel
     */
    reportId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportUpdateRequestModel
     */
    roles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportUpdateRequestModel
     */
    organizationIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportUpdateRequestModel
     */
    organizationTypes: Array<string>;
}

export function PowerBIReportUpdateRequestModelFromJSON(json: any): PowerBIReportUpdateRequestModel {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reportId': json['reportId'],
        'roles': json['roles'],
        'organizationIds': json['organizationIds'],
        'organizationTypes': json['organizationTypes'],
    };
}

export function PowerBIReportUpdateRequestModelToJSON(value?: PowerBIReportUpdateRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'id': value.id,
        'reportId': value.reportId,
        'roles': value.roles,
        'organizationIds': value.organizationIds,
        'organizationTypes': value.organizationTypes,
    };
}


