// tslint:disable
/**
 * DealerPolicy Reports Function App
 * Handles Reports
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerBIReportResponseModel
 */
export interface PowerBIReportResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportResponseModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportResponseModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportResponseModel
     */
    reportId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportResponseModel
     */
    roles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportResponseModel
     */
    organizationIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowerBIReportResponseModel
     */
    organizationTypes?: Array<string>;
}

export function PowerBIReportResponseModelFromJSON(json: any): PowerBIReportResponseModel {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'reportId': !exists(json, 'reportId') ? undefined : json['reportId'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'organizationIds': !exists(json, 'organizationIds') ? undefined : json['organizationIds'],
        'organizationTypes': !exists(json, 'organizationTypes') ? undefined : json['organizationTypes'],
    };
}

export function PowerBIReportResponseModelToJSON(value?: PowerBIReportResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'reportId': value.reportId,
        'roles': value.roles,
        'organizationIds': value.organizationIds,
        'organizationTypes': value.organizationTypes,
    };
}


