// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhoneNumberResponseModel
 */
export interface PhoneNumberResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberResponseModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberResponseModel
     */
    isDeleted?: boolean;
}

export function PhoneNumberResponseModelFromJSON(json: any): PhoneNumberResponseModel {
    return {
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function PhoneNumberResponseModelToJSON(value?: PhoneNumberResponseModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'phoneNumber': value.phoneNumber,
        'isDeleted': value.isDeleted,
    };
}


