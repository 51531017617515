// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StateEnabledModel,
    StateEnabledModelFromJSON,
    StateEnabledModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
} from '../models';

export interface RatingStatesGetRatingStateByStateAsyncRequest {
    stateAbbreviation: RatingStatesGetRatingStateByStateAsyncStateAbbreviationEnum;
}

/**
 * no description
 */
export class RatingStatesApi extends runtime.BaseAPI {

    /**
     */
    async ratingStatesGetRatingStateByStateAsyncRaw(requestParameters: RatingStatesGetRatingStateByStateAsyncRequest): Promise<runtime.ApiResponse<StateEnabledModel>> {
        if (requestParameters.stateAbbreviation === null || requestParameters.stateAbbreviation === undefined) {
            throw new runtime.RequiredError('stateAbbreviation','Required parameter requestParameters.stateAbbreviation was null or undefined when calling ratingStatesGetRatingStateByStateAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.stateAbbreviation !== undefined) {
            queryParameters['stateAbbreviation'] = requestParameters.stateAbbreviation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/rating-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StateEnabledModelFromJSON(jsonValue));
    }

    /**
     */
    async ratingStatesGetRatingStateByStateAsync(requestParameters: RatingStatesGetRatingStateByStateAsyncRequest): Promise<StateEnabledModel> {
        const response = await this.ratingStatesGetRatingStateByStateAsyncRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum RatingStatesGetRatingStateByStateAsyncStateAbbreviationEnum {
    Unknown = 'Unknown',
    AL = 'AL',
    AK = 'AK',
    AZ = 'AZ',
    AR = 'AR',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DE = 'DE',
    DC = 'DC',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    IA = 'IA',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    ME = 'ME',
    MD = 'MD',
    MA = 'MA',
    MI = 'MI',
    MN = 'MN',
    MS = 'MS',
    MO = 'MO',
    MT = 'MT',
    NE = 'NE',
    NV = 'NV',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NY = 'NY',
    NC = 'NC',
    ND = 'ND',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VT = 'VT',
    VA = 'VA',
    WA = 'WA',
    WV = 'WV',
    WI = 'WI',
    WY = 'WY'
}
