/**
 * @public
 */
export enum UnitedStatesAbbreviations {
    Unknown = 'Unknown',
    AK = 'AK',
    AL = 'AL',
    AR = 'AR',
    AZ = 'AZ',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DC = 'DC',
    DE = 'DE',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    IA = 'IA',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    MA = 'MA',
    MD = 'MD',
    ME = 'ME',
    MI = 'MI',
    MN = 'MN',
    MO = 'MO',
    MS = 'MS',
    MT = 'MT',
    NC = 'NC',
    ND = 'ND',
    NE = 'NE',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NV = 'NV',
    NY = 'NY',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VA = 'VA',
    VT = 'VT',
    WA = 'WA',
    WI = 'WI',
    WV = 'WV',
    WY = 'WY'
}

/**
 * @public
 */
export enum UnitedStatesNames {
    Unknown = 'Unknown',
    Alabama = 'Alabama',
    Alaska = 'Alaska',
    Arizona = 'Arizona',
    Arkansas = 'Arkansas',
    California = 'California',
    Colorado = 'Colorado',
    Connecticut = 'Connecticut',
    DistrictOfColumbia = 'District of Columbia',
    Delaware = 'Delaware',
    Florida = 'Florida',
    Georgia = 'Georgia',
    Hawaii = 'Hawaii',
    Idaho = 'Idaho',
    Illinois = 'Illinois',
    Indiana = 'Indiana',
    Iowa = 'Iowa',
    Kansas = 'Kansas',
    Kentucky = 'Kentucky',
    Louisiana = 'Louisiana',
    Maine = 'Maine',
    Maryland = 'Maryland',
    Massachusetts = 'Massachusetts',
    Michigan = 'Michigan',
    Minnesota = 'Minnesota',
    Mississippi = 'Mississippi',
    Missouri = 'Missouri',
    Montana = 'Montana',
    Nebraska = 'Nebraska',
    Nevada = 'Nevada',
    NewHampshire = 'New Hampshire',
    NewJersey = 'New Jersey',
    NewMexico = 'New Mexico',
    NewYork = 'New York',
    NorthCarolina = 'New York',
    NorthDakota = 'North Dakota',
    Ohio = 'Ohio',
    Oklahoma = 'Oklahoma',
    Oregon = 'Oregon',
    Pennsylvania = 'Pennsylvania',
    RhodeIsland = 'Rhode Island',
    SouthCarolina = 'South Carolina',
    SouthDakota = 'South Dakota',
    Tennessee = 'Tennessee',
    Texas = 'Texas',
    Utah = 'Utah',
    Vermont = 'Vermont',
    Virginia = 'Virginia',
    Washington = 'Washington',
    WestVirginia = 'West Virginia',
    Wisconsin = 'Wisconsin',
    Wyoming = 'Wyoming'
}

const getUnitedStatesName = (state: string): UnitedStatesNames => {
    switch (state) {
        case UnitedStatesAbbreviations.AL:
        case UnitedStatesNames.Alabama:
            return UnitedStatesNames.Alabama;
        case UnitedStatesAbbreviations.AK:
        case UnitedStatesNames.Alaska:
            return UnitedStatesNames.Alaska;
        case UnitedStatesAbbreviations.AZ:
        case UnitedStatesNames.Arizona:
            return UnitedStatesNames.Arizona;
        case UnitedStatesAbbreviations.AR:
        case UnitedStatesNames.Arkansas:
            return UnitedStatesNames.Arkansas;
        case UnitedStatesAbbreviations.CA:
        case UnitedStatesNames.California:
            return UnitedStatesNames.California;
        case UnitedStatesAbbreviations.CO:
        case UnitedStatesNames.Colorado:
            return UnitedStatesNames.Colorado;
        case UnitedStatesAbbreviations.CT:
        case UnitedStatesNames.Connecticut:
            return UnitedStatesNames.Connecticut;
        case UnitedStatesAbbreviations.DC:
        case UnitedStatesNames.DistrictOfColumbia:
            return UnitedStatesNames.DistrictOfColumbia;
        case UnitedStatesAbbreviations.DE:
        case UnitedStatesNames.Delaware:
            return UnitedStatesNames.Delaware;
        case UnitedStatesAbbreviations.FL:
        case UnitedStatesNames.Florida:
            return UnitedStatesNames.Florida;
        case UnitedStatesAbbreviations.GA:
        case UnitedStatesNames.Georgia:
            return UnitedStatesNames.Georgia;
        case UnitedStatesAbbreviations.HI:
        case UnitedStatesNames.Hawaii:
            return UnitedStatesNames.Hawaii;
        case UnitedStatesAbbreviations.ID:
        case UnitedStatesNames.Idaho:
            return UnitedStatesNames.Idaho;
        case UnitedStatesAbbreviations.IL:
        case UnitedStatesNames.Illinois:
            return UnitedStatesNames.Illinois;
        case UnitedStatesAbbreviations.IN:
        case UnitedStatesNames.Indiana:
            return UnitedStatesNames.Indiana;
        case UnitedStatesAbbreviations.IA:
        case UnitedStatesNames.Iowa:
            return UnitedStatesNames.Iowa;
        case UnitedStatesAbbreviations.KS:
        case UnitedStatesNames.Kansas:
            return UnitedStatesNames.Kansas;
        case UnitedStatesAbbreviations.KY:
        case UnitedStatesNames.Kentucky:
            return UnitedStatesNames.Kentucky;
        case UnitedStatesAbbreviations.LA:
        case UnitedStatesNames.Louisiana:
            return UnitedStatesNames.Louisiana;
        case UnitedStatesAbbreviations.MA:
        case UnitedStatesNames.Massachusetts:
            return UnitedStatesNames.Massachusetts;
        case UnitedStatesAbbreviations.MD:
        case UnitedStatesNames.Maryland:
            return UnitedStatesNames.Maryland;
        case UnitedStatesAbbreviations.ME:
        case UnitedStatesNames.Maine:
            return UnitedStatesNames.Maine;
        case UnitedStatesAbbreviations.MI:
        case UnitedStatesNames.Michigan:
            return UnitedStatesNames.Michigan;
        case UnitedStatesAbbreviations.MN:
        case UnitedStatesNames.Minnesota:
            return UnitedStatesNames.Minnesota;
        case UnitedStatesAbbreviations.MS:
        case UnitedStatesNames.Mississippi:
            return UnitedStatesNames.Mississippi;
        case UnitedStatesAbbreviations.MO:
        case UnitedStatesNames.Missouri:
            return UnitedStatesNames.Missouri;
        case UnitedStatesAbbreviations.MT:
        case UnitedStatesNames.Montana:
            return UnitedStatesNames.Montana;
        case UnitedStatesAbbreviations.NE:
        case UnitedStatesNames.Nebraska:
            return UnitedStatesNames.Nebraska;
        case UnitedStatesAbbreviations.NV:
        case UnitedStatesNames.Nevada:
            return UnitedStatesNames.Nevada;
        case UnitedStatesAbbreviations.NH:
        case UnitedStatesNames.NewHampshire:
            return UnitedStatesNames.NewHampshire;
        case UnitedStatesAbbreviations.NM:
        case UnitedStatesNames.NewMexico:
            return UnitedStatesNames.NewMexico;
        case UnitedStatesAbbreviations.NJ:
        case UnitedStatesNames.NewJersey:
            return UnitedStatesNames.NewJersey;
        case UnitedStatesAbbreviations.NY:
        case UnitedStatesNames.NewYork:
            return UnitedStatesNames.NewYork;
        case UnitedStatesAbbreviations.NC:
        case UnitedStatesNames.NorthCarolina:
            return UnitedStatesNames.NorthCarolina;
        case UnitedStatesAbbreviations.ND:
        case UnitedStatesNames.NorthDakota:
            return UnitedStatesNames.NorthDakota;
        case UnitedStatesAbbreviations.OH:
        case UnitedStatesNames.Ohio:
            return UnitedStatesNames.Ohio;
        case UnitedStatesAbbreviations.OK:
        case UnitedStatesNames.Oklahoma:
            return UnitedStatesNames.Oklahoma;
        case UnitedStatesAbbreviations.OR:
        case UnitedStatesNames.Oregon:
            return UnitedStatesNames.Oregon;
        case UnitedStatesAbbreviations.PA:
        case UnitedStatesNames.Pennsylvania:
            return UnitedStatesNames.Pennsylvania;
        case UnitedStatesAbbreviations.RI:
        case UnitedStatesNames.RhodeIsland:
            return UnitedStatesNames.RhodeIsland;
        case UnitedStatesAbbreviations.SC:
        case UnitedStatesNames.SouthCarolina:
            return UnitedStatesNames.SouthCarolina;
        case UnitedStatesAbbreviations.SD:
        case UnitedStatesNames.SouthDakota:
            return UnitedStatesNames.SouthDakota;
        case UnitedStatesAbbreviations.TN:
        case UnitedStatesNames.Tennessee:
            return UnitedStatesNames.Tennessee;
        case UnitedStatesAbbreviations.TX:
        case UnitedStatesNames.Texas:
            return UnitedStatesNames.Texas;
        case UnitedStatesAbbreviations.UT:
        case UnitedStatesNames.Utah:
            return UnitedStatesNames.Utah;
        case UnitedStatesAbbreviations.VA:
        case UnitedStatesNames.Virginia:
            return UnitedStatesNames.Virginia;
        case UnitedStatesAbbreviations.VT:
        case UnitedStatesNames.Vermont:
            return UnitedStatesNames.Vermont;
        case UnitedStatesAbbreviations.WA:
        case UnitedStatesNames.Washington:
            return UnitedStatesNames.Washington;
        case UnitedStatesAbbreviations.WI:
        case UnitedStatesNames.Wisconsin:
            return UnitedStatesNames.Wisconsin;
        case UnitedStatesAbbreviations.WV:
        case UnitedStatesNames.WestVirginia:
            return UnitedStatesNames.WestVirginia;
        case UnitedStatesAbbreviations.WY:
        case UnitedStatesNames.Wyoming:
            return UnitedStatesNames.Wyoming;
        default:
            return UnitedStatesNames.Unknown;
    }
};

const getUnitedStatesAbbreviation = (state: string): UnitedStatesAbbreviations => {
    switch (state) {
        case UnitedStatesAbbreviations.AL:
        case UnitedStatesNames.Alabama:
            return UnitedStatesAbbreviations.AL;
        case UnitedStatesAbbreviations.AK:
        case UnitedStatesNames.Alaska:
            return UnitedStatesAbbreviations.AK;
        case UnitedStatesAbbreviations.AZ:
        case UnitedStatesNames.Arizona:
            return UnitedStatesAbbreviations.AZ;
        case UnitedStatesAbbreviations.AR:
        case UnitedStatesNames.Arkansas:
            return UnitedStatesAbbreviations.AR;
        case UnitedStatesAbbreviations.CA:
        case UnitedStatesNames.California:
            return UnitedStatesAbbreviations.CA;
        case UnitedStatesAbbreviations.CO:
        case UnitedStatesNames.Colorado:
            return UnitedStatesAbbreviations.CO;
        case UnitedStatesAbbreviations.CT:
        case UnitedStatesNames.Connecticut:
            return UnitedStatesAbbreviations.CT;
        case UnitedStatesAbbreviations.DE:
        case UnitedStatesNames.Delaware:
            return UnitedStatesAbbreviations.DE;
        case UnitedStatesAbbreviations.DC:
        case UnitedStatesNames.DistrictOfColumbia:
            return UnitedStatesAbbreviations.DC;
        case UnitedStatesAbbreviations.FL:
        case UnitedStatesNames.Florida:
            return UnitedStatesAbbreviations.FL;
        case UnitedStatesAbbreviations.GA:
        case UnitedStatesNames.Georgia:
            return UnitedStatesAbbreviations.GA;
        case UnitedStatesAbbreviations.HI:
        case UnitedStatesNames.Hawaii:
            return UnitedStatesAbbreviations.HI;
        case UnitedStatesAbbreviations.ID:
        case UnitedStatesNames.Idaho:
            return UnitedStatesAbbreviations.ID;
        case UnitedStatesAbbreviations.IL:
        case UnitedStatesNames.Illinois:
            return UnitedStatesAbbreviations.IL;
        case UnitedStatesAbbreviations.IN:
        case UnitedStatesNames.Indiana:
            return UnitedStatesAbbreviations.IN;
        case UnitedStatesAbbreviations.IA:
        case UnitedStatesNames.Iowa:
            return UnitedStatesAbbreviations.IA;
        case UnitedStatesAbbreviations.KS:
        case UnitedStatesNames.Kansas:
            return UnitedStatesAbbreviations.KS;
        case UnitedStatesAbbreviations.KY:
        case UnitedStatesNames.Kentucky:
            return UnitedStatesAbbreviations.KY;
        case UnitedStatesAbbreviations.LA:
        case UnitedStatesNames.Louisiana:
            return UnitedStatesAbbreviations.LA;
        case UnitedStatesAbbreviations.MA:
        case UnitedStatesNames.Massachusetts:
            return UnitedStatesAbbreviations.MA;
        case UnitedStatesAbbreviations.MD:
        case UnitedStatesNames.Maryland:
            return UnitedStatesAbbreviations.MD;
        case UnitedStatesAbbreviations.ME:
        case UnitedStatesNames.Maine:
            return UnitedStatesAbbreviations.ME;
        case UnitedStatesAbbreviations.MI:
        case UnitedStatesNames.Michigan:
            return UnitedStatesAbbreviations.MI;
        case UnitedStatesAbbreviations.MN:
        case UnitedStatesNames.Minnesota:
            return UnitedStatesAbbreviations.MN;
        case UnitedStatesAbbreviations.MS:
        case UnitedStatesNames.Mississippi:
            return UnitedStatesAbbreviations.MS;
        case UnitedStatesAbbreviations.MO:
        case UnitedStatesNames.Missouri:
            return UnitedStatesAbbreviations.MO;
        case UnitedStatesAbbreviations.MT:
        case UnitedStatesNames.Montana:
            return UnitedStatesAbbreviations.MT;
        case UnitedStatesAbbreviations.NE:
        case UnitedStatesNames.Nebraska:
            return UnitedStatesAbbreviations.NE;
        case UnitedStatesAbbreviations.NV:
        case UnitedStatesNames.Nevada:
            return UnitedStatesAbbreviations.NV;
        case UnitedStatesAbbreviations.NH:
        case UnitedStatesNames.NewHampshire:
            return UnitedStatesAbbreviations.NH;
        case UnitedStatesAbbreviations.NM:
        case UnitedStatesNames.NewMexico:
            return UnitedStatesAbbreviations.NM;
        case UnitedStatesAbbreviations.NJ:
        case UnitedStatesNames.NewJersey:
            return UnitedStatesAbbreviations.NJ;
        case UnitedStatesAbbreviations.NY:
        case UnitedStatesNames.NewYork:
            return UnitedStatesAbbreviations.NY;
        case UnitedStatesAbbreviations.NC:
        case UnitedStatesNames.NorthCarolina:
            return UnitedStatesAbbreviations.NC;
        case UnitedStatesAbbreviations.ND:
        case UnitedStatesNames.NorthDakota:
            return UnitedStatesAbbreviations.ND;
        case UnitedStatesAbbreviations.OH:
        case UnitedStatesNames.Ohio:
            return UnitedStatesAbbreviations.OH;
        case UnitedStatesAbbreviations.OK:
        case UnitedStatesNames.Oklahoma:
            return UnitedStatesAbbreviations.OK;
        case UnitedStatesAbbreviations.OR:
        case UnitedStatesNames.Oregon:
            return UnitedStatesAbbreviations.OR;
        case UnitedStatesAbbreviations.PA:
        case UnitedStatesNames.Pennsylvania:
            return UnitedStatesAbbreviations.PA;
        case UnitedStatesAbbreviations.RI:
        case UnitedStatesNames.RhodeIsland:
            return UnitedStatesAbbreviations.RI;
        case UnitedStatesAbbreviations.SC:
        case UnitedStatesNames.SouthCarolina:
            return UnitedStatesAbbreviations.SC;
        case UnitedStatesAbbreviations.SD:
        case UnitedStatesNames.SouthDakota:
            return UnitedStatesAbbreviations.SD;
        case UnitedStatesAbbreviations.TN:
        case UnitedStatesNames.Tennessee:
            return UnitedStatesAbbreviations.TN;
        case UnitedStatesAbbreviations.TX:
        case UnitedStatesNames.Texas:
            return UnitedStatesAbbreviations.TX;
        case UnitedStatesAbbreviations.UT:
        case UnitedStatesNames.Utah:
            return UnitedStatesAbbreviations.UT;
        case UnitedStatesAbbreviations.VA:
        case UnitedStatesNames.Virginia:
            return UnitedStatesAbbreviations.VA;
        case UnitedStatesAbbreviations.VT:
        case UnitedStatesNames.Vermont:
            return UnitedStatesAbbreviations.VT;
        case UnitedStatesAbbreviations.WA:
        case UnitedStatesNames.Washington:
            return UnitedStatesAbbreviations.WA;
        case UnitedStatesAbbreviations.WI:
        case UnitedStatesNames.Wisconsin:
            return UnitedStatesAbbreviations.WI;
        case UnitedStatesAbbreviations.WV:
        case UnitedStatesNames.WestVirginia:
            return UnitedStatesAbbreviations.WV;
        case UnitedStatesAbbreviations.WY:
        case UnitedStatesNames.Wyoming:
            return UnitedStatesAbbreviations.WY;
        default:
            return UnitedStatesAbbreviations.Unknown;
    }
};

/**
 * @public
 */
const UnitedStates = {
    getUnitedStatesAbbreviation: getUnitedStatesAbbreviation,
    getUnitedStatesName: getUnitedStatesName,
    UnitedStatesAbbreviations: UnitedStatesAbbreviations,
    UnitedStatesNames: UnitedStatesNames
};

export default UnitedStates;
