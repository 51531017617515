// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDeleteRequestModel
 */
export interface UserDeleteRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserDeleteRequestModel
     */
    modifiedByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDeleteRequestModel
     */
    deactivationReasonType: UserDeleteRequestModelDeactivationReasonTypeEnum;
}

export function UserDeleteRequestModelFromJSON(json: any): UserDeleteRequestModel {
    return {
        'modifiedByUserId': json['modifiedByUserId'],
        'deactivationReasonType': json['deactivationReasonType'],
    };
}

export function UserDeleteRequestModelToJSON(value?: UserDeleteRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'modifiedByUserId': value.modifiedByUserId,
        'deactivationReasonType': value.deactivationReasonType,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserDeleteRequestModelDeactivationReasonTypeEnum {
    Unknown = 'Unknown',
    Other = 'Other',
    SystemMisuse = 'SystemMisuse',
    UserLeftOrganization = 'UserLeftOrganization',
    OrganizationDeactivated = 'OrganizationDeactivated',
    Inactivity = 'Inactivity',
    OnboardingIssues = 'OnboardingIssues',
    IpRestricted = 'IpRestricted',
    DeviceRestricted = 'DeviceRestricted'
}


