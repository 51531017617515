// tslint:disable
/**
 * DealerPolicy Auth API
 * The DealerPolicy Auth API Provides authentication/authorization routes for JWT, temporary tokens, and ReCAPTCHA.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginRequestModel
 */
export interface LoginRequestModel {
    /**
     * 
     * @type {string}
     * @memberof LoginRequestModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestModel
     */
    password: string;
}

export function LoginRequestModelFromJSON(json: any): LoginRequestModel {
    return {
        'username': json['username'],
        'password': json['password'],
    };
}

export function LoginRequestModelToJSON(value?: LoginRequestModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'password': value.password,
    };
}


