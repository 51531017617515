// tslint:disable
/**
 * DealerPolicy Marketplace Function App
 * The orchestrator API exclusively for Marketplace.
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LinkRequestModel,
    LinkRequestModelFromJSON,
    LinkRequestModelToJSON,
    LinkResponseModel,
    LinkResponseModelFromJSON,
    LinkResponseModelToJSON,
    SwaggerSimpleError,
    SwaggerSimpleErrorFromJSON,
    SwaggerSimpleErrorToJSON,
} from '../models';

export interface DealershipUsersGetConsumerLinksAsyncRequest {
    dealershipid: string;
    userid: string;
    consumerid: string;
}

export interface DealershipUsersGetConsumerQuotesLinksAsyncRequest {
    dealershipid: string;
    userid: string;
    consumerid: string;
}

export interface DealershipUsersGetDealerLinksAsyncRequest {
    dealershipid: string;
    userid: string;
    consumerid: string;
}

export interface DealershipUsersGetDealerQuotesLinksAsyncRequest {
    dealershipid: string;
    userid: string;
    consumerid: string;
}

export interface DealershipUsersPostConsumerLinkAsyncRequest {
    dealershipid: string;
    userid: string;
    linkRequestModel?: LinkRequestModel;
}

export interface DealershipUsersPostDealerLinkAsyncRequest {
    dealershipid: string;
    userid: string;
    linkRequestModel?: LinkRequestModel;
}

/**
 * no description
 */
export class DealershipUsersApi extends runtime.BaseAPI {

    /**
     */
    async dealershipUsersGetConsumerLinksAsyncRaw(requestParameters: DealershipUsersGetConsumerLinksAsyncRequest): Promise<runtime.ApiResponse<LinkResponseModel>> {
        if (requestParameters.dealershipid === null || requestParameters.dealershipid === undefined) {
            throw new runtime.RequiredError('dealershipid','Required parameter requestParameters.dealershipid was null or undefined when calling dealershipUsersGetConsumerLinksAsync.');
        }

        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling dealershipUsersGetConsumerLinksAsync.');
        }

        if (requestParameters.consumerid === null || requestParameters.consumerid === undefined) {
            throw new runtime.RequiredError('consumerid','Required parameter requestParameters.consumerid was null or undefined when calling dealershipUsersGetConsumerLinksAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.consumerid !== undefined) {
            queryParameters['consumerid'] = requestParameters.consumerid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/dealerships/{dealershipid}/users/{userid}/links/consumer`.replace(`{${"dealershipid"}}`, encodeURIComponent(String(requestParameters.dealershipid))).replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async dealershipUsersGetConsumerLinksAsync(requestParameters: DealershipUsersGetConsumerLinksAsyncRequest): Promise<LinkResponseModel> {
        const response = await this.dealershipUsersGetConsumerLinksAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealershipUsersGetConsumerQuotesLinksAsyncRaw(requestParameters: DealershipUsersGetConsumerQuotesLinksAsyncRequest): Promise<runtime.ApiResponse<LinkResponseModel>> {
        if (requestParameters.dealershipid === null || requestParameters.dealershipid === undefined) {
            throw new runtime.RequiredError('dealershipid','Required parameter requestParameters.dealershipid was null or undefined when calling dealershipUsersGetConsumerQuotesLinksAsync.');
        }

        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling dealershipUsersGetConsumerQuotesLinksAsync.');
        }

        if (requestParameters.consumerid === null || requestParameters.consumerid === undefined) {
            throw new runtime.RequiredError('consumerid','Required parameter requestParameters.consumerid was null or undefined when calling dealershipUsersGetConsumerQuotesLinksAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.consumerid !== undefined) {
            queryParameters['consumerid'] = requestParameters.consumerid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/dealerships/{dealershipid}/users/{userid}/links/consumer-quotes`.replace(`{${"dealershipid"}}`, encodeURIComponent(String(requestParameters.dealershipid))).replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async dealershipUsersGetConsumerQuotesLinksAsync(requestParameters: DealershipUsersGetConsumerQuotesLinksAsyncRequest): Promise<LinkResponseModel> {
        const response = await this.dealershipUsersGetConsumerQuotesLinksAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealershipUsersGetDealerLinksAsyncRaw(requestParameters: DealershipUsersGetDealerLinksAsyncRequest): Promise<runtime.ApiResponse<LinkResponseModel>> {
        if (requestParameters.dealershipid === null || requestParameters.dealershipid === undefined) {
            throw new runtime.RequiredError('dealershipid','Required parameter requestParameters.dealershipid was null or undefined when calling dealershipUsersGetDealerLinksAsync.');
        }

        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling dealershipUsersGetDealerLinksAsync.');
        }

        if (requestParameters.consumerid === null || requestParameters.consumerid === undefined) {
            throw new runtime.RequiredError('consumerid','Required parameter requestParameters.consumerid was null or undefined when calling dealershipUsersGetDealerLinksAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.consumerid !== undefined) {
            queryParameters['consumerid'] = requestParameters.consumerid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/dealerships/{dealershipid}/users/{userid}/links/dealer`.replace(`{${"dealershipid"}}`, encodeURIComponent(String(requestParameters.dealershipid))).replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async dealershipUsersGetDealerLinksAsync(requestParameters: DealershipUsersGetDealerLinksAsyncRequest): Promise<LinkResponseModel> {
        const response = await this.dealershipUsersGetDealerLinksAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealershipUsersGetDealerQuotesLinksAsyncRaw(requestParameters: DealershipUsersGetDealerQuotesLinksAsyncRequest): Promise<runtime.ApiResponse<LinkResponseModel>> {
        if (requestParameters.dealershipid === null || requestParameters.dealershipid === undefined) {
            throw new runtime.RequiredError('dealershipid','Required parameter requestParameters.dealershipid was null or undefined when calling dealershipUsersGetDealerQuotesLinksAsync.');
        }

        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling dealershipUsersGetDealerQuotesLinksAsync.');
        }

        if (requestParameters.consumerid === null || requestParameters.consumerid === undefined) {
            throw new runtime.RequiredError('consumerid','Required parameter requestParameters.consumerid was null or undefined when calling dealershipUsersGetDealerQuotesLinksAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.consumerid !== undefined) {
            queryParameters['consumerid'] = requestParameters.consumerid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/dealerships/{dealershipid}/users/{userid}/links/dealer-quotes`.replace(`{${"dealershipid"}}`, encodeURIComponent(String(requestParameters.dealershipid))).replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async dealershipUsersGetDealerQuotesLinksAsync(requestParameters: DealershipUsersGetDealerQuotesLinksAsyncRequest): Promise<LinkResponseModel> {
        const response = await this.dealershipUsersGetDealerQuotesLinksAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealershipUsersPostConsumerLinkAsyncRaw(requestParameters: DealershipUsersPostConsumerLinkAsyncRequest): Promise<runtime.ApiResponse<LinkResponseModel>> {
        if (requestParameters.dealershipid === null || requestParameters.dealershipid === undefined) {
            throw new runtime.RequiredError('dealershipid','Required parameter requestParameters.dealershipid was null or undefined when calling dealershipUsersPostConsumerLinkAsync.');
        }

        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling dealershipUsersPostConsumerLinkAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/dealerships/{dealershipid}/users/{userid}/links/consumer`.replace(`{${"dealershipid"}}`, encodeURIComponent(String(requestParameters.dealershipid))).replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRequestModelToJSON(requestParameters.linkRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async dealershipUsersPostConsumerLinkAsync(requestParameters: DealershipUsersPostConsumerLinkAsyncRequest): Promise<LinkResponseModel> {
        const response = await this.dealershipUsersPostConsumerLinkAsyncRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealershipUsersPostDealerLinkAsyncRaw(requestParameters: DealershipUsersPostDealerLinkAsyncRequest): Promise<runtime.ApiResponse<LinkResponseModel>> {
        if (requestParameters.dealershipid === null || requestParameters.dealershipid === undefined) {
            throw new runtime.RequiredError('dealershipid','Required parameter requestParameters.dealershipid was null or undefined when calling dealershipUsersPostDealerLinkAsync.');
        }

        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling dealershipUsersPostDealerLinkAsync.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/marketplace/dealerships/{dealershipid}/users/{userid}/links/dealer`.replace(`{${"dealershipid"}}`, encodeURIComponent(String(requestParameters.dealershipid))).replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRequestModelToJSON(requestParameters.linkRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async dealershipUsersPostDealerLinkAsync(requestParameters: DealershipUsersPostDealerLinkAsyncRequest): Promise<LinkResponseModel> {
        const response = await this.dealershipUsersPostDealerLinkAsyncRaw(requestParameters);
        return await response.value();
    }

}
